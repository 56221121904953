// Referências externas
import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

// Referências internas
import { Loading } from '../../../common';

// Estilo
import '../styles.css';

export default function ModalNovaProposta(props) {
    const [buscaListaClientes, setBuscaListaClientes] = useState('');

    return (
        <div className="modal-fundo">
            <div className="modal3">
                <div className="modal-conteudo2">
                    <div className="modal-header">
                        <h2>Selecione um cliente</h2>
                        <button className="botao-fechar-modal" onClick={() => props.fechar()} >
                            <FiX />
                        </button>
                    </div>
                    <div className="">
                        {props.listaClientesCarregando ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (
                            <>
                                <input
                                    autoFocus
                                    name="nome_razao_cliente"
                                    className="text-input-search2"
                                    type="text"
                                    id="nome_razao_cliente"
                                    placeholder="Pesquisar"
                                    onChange={(e) => setBuscaListaClientes(String(e.target.value))} 
                                    autoComplete="off"
                                />

                                <h4> Nome Completo / Razão Social </h4>

                                <div className="modal-body">
                                    <table className="select-customer">
                                        <tbody>
                                            {props.clientes
                                                .filter(cliente => {
                                                    const cliente_atual_normalizado = props.normalizar_texto(cliente.nome_razao_cliente);
                                                    const cliente_desejado_normalizado = props.normalizar_texto(buscaListaClientes);
            
                                                    if (cliente_atual_normalizado.includes(cliente_desejado_normalizado)) {
                                                        return cliente;
                                                    }
                                                })
                                                .map(cliente => (
                                                    <tr key={cliente.ID_cliente}>
                                                        <td>{cliente.nome_razao_cliente}</td>
                                                        <td>
                                                            <button onClick={() => props.novaProposta(cliente)}>
                                                                Selecionar cliente
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                
                                <p className="modal-nova-proposta-cadastro-cliente">
                                    Cliente ainda não cadastrado? <Link to={{pathname: "/clientes", state: { gerarPropostaAposCadastro: true, origem: '/propostas' }}}>Clique aqui para inclui-lo.</Link>
                                </p>
                            </>
                        )}
                    </div>
                    
                </div>
            </div>
        </div>
    );
}