// Referências externas
import React from 'react';

// Referências internas
import { Loading } from '../../../../common';

export default function Resumo (props) {
    let area_total = 0;
    let peso_total = 0;

    if (props.tipo_estrutura && props.dadosRoute && props.sistema_modulo) {
        let estrutura = props.tipo_estrutura.split(' ', 1)[0];
        let correcao_area = 0;
        if (estrutura === 'Laje') {
          correcao_area = props.dadosRoute.informacao_complementar_float.correcao_area_laje.valor;
        } else if(estrutura === 'Solo') {
          correcao_area = 4;
        } else {
          correcao_area = props.dadosRoute.informacao_complementar_float.correcao_area_telhado.valor;
        }
        
        area_total = Math.ceil(correcao_area * props.sistema_modulo[0].quant * props.sistema_modulo[0].area);
        peso_total = Math.ceil(props.sistema_modulo[0].peso * props.sistema_modulo[0].quant);
    }
    
    return (
        <>
            {!props.done ? (
                <div>
                    <Loading />
                </div>
                    )  :  ( 
                <>
                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Sist. Determinado</th>
                                <td>{parseFloat(props.resumo.sistema_sugerido).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})} kWp</td>
                            </tr>
                            <tr>
                                <th>Área ocupada</th>
                                <td>{parseFloat(area_total).toLocaleString('pt-BR', {style: 'decimal'})} m²</td>
                            </tr>
                            <tr>
                                <th>Peso total</th>
                                <td>{parseFloat(peso_total).toLocaleString('pt-BR', {style: 'decimal'})} kg</td>
                            </tr>
                            <tr>
                                <th>Pot. AC nominal</th>
                                <td>{parseFloat(props.resumo.potencia_total).toLocaleString('pt-BR', {style: 'decimal'})} kW</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
        </>
    )
}