export default function validarEstado(estadoProposta) {
  let erros = {};

  console.log(estadoProposta)
  console.log(estadoProposta.tabela)
  if (!estadoProposta.tabela.trim()) {
    erros.tabela = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (!estadoProposta.responsavel.trim()) {
    erros.responsavel = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (estadoProposta.comissao === "") {
    erros.comissao = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoProposta.comissao < 0 || estadoProposta.comissao > 25) {
    erros.comissao = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoProposta.outros_valores < 0) {
    erros.outros_valores = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoProposta.margem_proposta < 0 || estadoProposta.margem_proposta > 100) {
    erros.margem_proposta = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoProposta.imposto_proposta < 0 || estadoProposta.imposto_proposta > 100) {
    erros.imposto_proposta = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }
  
  return erros;

}