// Referências externas
import React from 'react';

// Referências internas
import { Loading } from '../../../../common';

export default function Resumo (props) {
    return (
        <>
            {!props.done ? (
                <div>
                    <Loading />
                </div>
                    )  :  ( 
                <>
                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Produto</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_produto)}</td>
                            </tr>
                            <tr>
                                <th>Outros</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.estadoProposta.outros_valores)}</td>
                            </tr>
                            <tr>
                                <th>Imposto</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_final * props.estadoProposta.imposto_proposta / 100)}</td>
                            </tr>
                            <tr>
                                <th>Comissão</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_comissao)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results2">
                        <tbody>
                            <tr className="destaque_ganhos">
                                <th rowSpan={2}>Margem efetiva</th>
                                <td>
                                    {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(
                                        (
                                            props.resumo.valor_final
                                            - props.resumo.parcial_produto
                                            - props.estadoProposta.outros_valores
                                            - (props.resumo.valor_final * props.estadoProposta.imposto_proposta / 100)
                                            - props.resumo.valor_comissao
                                        )
                                    )}
                                </td>
                            </tr>
                            <tr className="destaque_ganhos">
                                <td>
                                    {props.resumo.valor_final > 0 ? (
                                        Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(
                                            (
                                                props.resumo.valor_final
                                                - props.resumo.parcial_produto
                                                - props.estadoProposta.outros_valores
                                                - (props.resumo.valor_final * props.estadoProposta.imposto_proposta / 100)
                                                - props.resumo.valor_comissao
                                            )
                                            /
                                            props.resumo.valor_final
                                        )
                                    ) : (
                                        Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results2">
                        <tbody>
                            <tr className="valor_final">
                                <th>Valor Global</th>
                                <th>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_final)}</th>
                            </tr>
                        </tbody>
                    </table>

                    <br />

                    <table className="table-results2">
                        <tbody>
                            <tr>
                                <th colSpan="2">WEG</th>
                            </tr>
                            <tr>
                                <th>Produto</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_produto)}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results2">
                        <tbody>
                            <tr>
                                <th colSpan="2">Brazil Solution</th>
                            </tr>
                            <tr>
                                <th>Serviço</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_servico)}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
        </>
    )
}