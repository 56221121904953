// Referências externas
import React, { useState, useEffect } from 'react';
import { FiX, FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { DynamicLoading } from '../../../common';
import validateModalSalvarComo from './validateModalSalvarComo';
import useModalSalvarComoForm from './useModalSalvarComoForm';

// Importar componente botão verde girando branco

// Estilo
import '../styles.css';


export default function (props) {
    const {handleChange, salvar, values, errors} = useModalSalvarComoForm(validateModalSalvarComo, props.salvarProposta, props.setModalSalvarComoAberto);

    useEffect(() => {   

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="modal-fundo">
            <div className="modal-salvar-como">
                <div className="modal-conteudo2">
                    <div className="modal-header">
                        <h2 className="wrapper-titulo"> Salvar proposta como </h2>
                        <button className="botao-fechar-modal" onClick={() => props.setModalSalvarComoAberto(false)} >
                            <FiX />
                        </button>
                    </div>
                    <form onSubmit={e => { salvar(e); }}>
                        <div className="modal-salvar-como-form-wrapper">
                            <div className="modal-salvar-como-input-group">
                                <label htmlFor="numero_proposta" className="label-client-text">
                                    Nº proposta {errors.numero_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                                </label>
                                <input
                                    className="modal-salvar-como-input"
                                    type="text"
                                    id="numero_proposta"
                                    name="numero_proposta"
                                    value={values.numero_proposta}
                                    onChange={e => { handleChange(e); }}
                                />
                            </div>

                            <div className="modal-salvar-como-input-group">
                                <label htmlFor="revisao_proposta" className="label-client-text">
                                    Revisão {errors.revisao_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                                </label>
                                <input
                                    className="modal-salvar-como-input"
                                    type="text"
                                    id="revisao_proposta"
                                    name="revisao_proposta"
                                    value={values.revisao_proposta}  
                                    onChange={e => { handleChange(e); }}
                                />
                            </div>
                        </div>

                        <div className="modal-salvar-como-botoes">
                            <button className="button-cancel" onClick={() => props.setModalSalvarComoAberto(false)}> Cancelar </button>

                            {props.salvarCarregando ? (
                                <button className="modal-cliente-submit-carregando"> <DynamicLoading width='40px' /> </button>
                            ):(
                                <button className="button-submit" type="submit"> Salvar </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}