// Referências externas
import React, { useState, useEffect } from 'react';
import { FiFilePlus, FiTrash2, FiHelpCircle, FiBatteryCharging } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading, DynamicLoading } from '../../common';
import api from '../../services/api';
import ModalNovaProposta from './form/ModalNovaProposta';
import ModalPadrao2 from '../../common/Modais/ModalPadrao2';

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

export default function PropostasGR() {
    const history = useHistory();
    const [token, setToken] = useState({});

    const [proposals, setProposals] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [done, setDone] = useState(true);
    const [doneDeletar, setDoneDeletar] = useState(true);
    const [listaClientesCarregando, setListaClientesCarregando] = useState(true);

    const [buscaListaPropostas, setBuscaListaPropostas] = useState('');
    const [modalBuscaClienteAberto, setModalBuscaClienteAberto] = useState(false);

    const [modalConfirmarExclusaoAberto, setModalConfirmarExclusaoAberto] = useState(false);
    const [propostaExclusao, setPropostaExclusao] = useState({});

    async function obterPropostaCompleta(propostaSelecionadaResumida) {
        const numero_proposta = propostaSelecionadaResumida.numero_proposta;
        const revisao_proposta = propostaSelecionadaResumida.revisao_proposta;

        const response = await api.get(`propostas_gr/${numero_proposta}/${revisao_proposta}`);
        const propostaCompleta = response.data;
        
        return propostaCompleta[0];
    }

    function vocativo(proposta) {
        if(proposta.titulo_cliente !== "N/A") {
            return proposta.titulo_cliente + " " + proposta.nome_razao_cliente;

        } else {
            return proposta.nome_razao_cliente;
        }
    }

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }

    const notificarSucessoGenerico = () => {
        toast.success('Operação executada com sucesso!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function novaProposta(id) {
        history.push({
            pathname: `propostas_gr/simular`,
            origin: '/propostas_gr',
            data:  id
        })        
    }

    async function editarProposta(propostaSelecionadaResumida) {
        const propostaSelecionada = await obterPropostaCompleta(propostaSelecionadaResumida);

        const id = {
            ID_cliente: propostaSelecionada.ID_cliente,
            titulo_cliente: propostaSelecionada.titulo_cliente,
            nome_razao_cliente: propostaSelecionada.nome_razao_cliente
        }

        history.push({
            pathname: `propostas_gr/simular`,
            origin: '/propostas_gr',
            data:  id,
            proposal: propostaSelecionada
        })        
    }

    async function novaRevisao(propostaSelecionadaResumida) {
        const propostaSelecionada = await obterPropostaCompleta(propostaSelecionadaResumida);

        try {
            setDone(false);
    
            const send = {
                "numero_proposta_duplicada": propostaSelecionada.numero_proposta,
                "revisao_proposta_duplicada": propostaSelecionada.revisao_proposta
            }
            const response = await api.post('propostas_gr/duplicar', send);
            const propostaRevisada = response.data;
    
            const id = {
                ID_cliente: propostaRevisada.ID_cliente,
                titulo_cliente: propostaRevisada.titulo_cliente,
                nome_razao_cliente: propostaRevisada.nome_razao_cliente
            }
    
            history.push({
                pathname: `propostas_gr/simular`,
                origin: '/propostas_gr',
                data: id,
                proposal: propostaRevisada
            })
    
            setDone(true);    
        } catch (error) {
            
        }   
    }

    async function deletarProposta(propostaExclusaoResumida) {
        setDoneDeletar(false);

        const propostaExclusao = await obterPropostaCompleta(propostaExclusaoResumida);
        
        try {
            await api.delete(`propostas_gr/${propostaExclusao.numero_proposta}/${propostaExclusao.revisao_proposta}/1`);
            setProposals(proposals.filter(proposal => (
                proposal.numero_proposta !== propostaExclusao.numero_proposta ||
                proposal.revisao_proposta !== propostaExclusao.revisao_proposta
            )));

            notificarSucessoGenerico();
        } catch (error) {
            notificarErro();
        }

        setDoneDeletar(true);
    }    

    useEffect(() => {
        document.title = `SGC Brazil Solution`;
        setToken(JSON.parse(localStorage.getItem('sgc_br')));

        async function solicitarPropostasEClientes() {
            setDone(false);
            try {
                const response = await api.get('propostas_gr', {
                    timeout: 15000
                });

                setProposals(response.data);
            } catch (error) {
                notificarErro();
            }
            setDone(true);
            
            setListaClientesCarregando(true);
            try {
                const response = await api.get('clientes')
                setCustomers(response.data);
            } catch (error) {
                notificarErro();
            }
            setListaClientesCarregando(false);
        }

        solicitarPropostasEClientes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />
            {modalConfirmarExclusaoAberto &&
                <ModalPadrao2
                    titulo = {"ATENÇÃO!"}
                    mensagem1 = {"A exclusão da proposta é irreversível!"}
                    parametro1 = {{"nome": "Nº da proposta:", "valor": propostaExclusao.numero_proposta}}
                    parametro2 = {{"nome": "Revisão da proposta:", "valor": propostaExclusao.revisao_proposta}}
                    parametro3 = {{"nome": "Agente Comercial:", "valor": propostaExclusao.nome_usuario}}
                    parametro4 = {{"nome": "Criador Proposta:", "valor": propostaExclusao.nome_usuario_criador}}
                    parametro5 = {{"nome": "Cliente:", "valor": vocativo(propostaExclusao)}}
                    parametro6 = {{"nome": "Valor:", "valor": Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(propostaExclusao.valor_final)}}
                    parametro7 = {{"nome": "Data:", "valor": (new Date(propostaExclusao.data_apresentacao_proposta)).toLocaleDateString()}}
                    mensagemConfirmar = {"Deseja prosseguir com a exclusão da proposta?"}
                    fecharModal = {() => setModalConfirmarExclusaoAberto(false)}
                    acaoModal = {() => {deletarProposta(propostaExclusao); setModalConfirmarExclusaoAberto(false);}}
                    confirmar = {"Excluir"}
                />
            }

            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            <div>Propostas GR <FiBatteryCharging size={13}/></div>
                        </div>

                        <div className="text-input-search-wraper">
                            <input
                                autoFocus
                                name="busca_lista_proposta"
                                className="text-input-search"
                                type="text"
                                id="busca_lista_proposta"
                                placeholder="Pesquisar"
                                onChange={e => { setBuscaListaPropostas(e.target.value); }}
                                autoComplete="off"
                            />
                            
                            <div className="icone-ajuda">
                                <FiHelpCircle size={18}/>
                            </div>

                            <div className="esconder-ajuda">
                                É possível buscar por número de proposta e nome do cliente
                            </div>
                        </div>

                        { modalBuscaClienteAberto && <ModalNovaProposta listaClientesCarregando={listaClientesCarregando} clientes={customers} fechar={() => setModalBuscaClienteAberto(false)} novaProposta={novaProposta} normalizar_texto={normalizar_texto} /> }

                        <button className="type1-main-button"  onClick={() => setModalBuscaClienteAberto(true)} >
                            <FiFilePlus/>
                            <span>Nova proposta GR</span>
                        </button>
                    </div>
                    <div className="type3-content-gr">

                        {!done ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (
                            <table className="proposals-table">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Revisão</th>
                                        <th>Cliente</th>
                                        <th>Data</th>
                                        <th>Valor</th>
                                        <th>Agente Comercial</th>
                                        <th>Criador Proposta</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {proposals
                                        .filter(proposal => {
                                            if (buscaListaPropostas.length > 0 && buscaListaPropostas.replace(new RegExp('[0-9]','gi'), '') === '') {
                                                const proposta_atual_normalizada = normalizar_texto(proposal.numero_proposta);
                                                const proposta_desejada_normalizada = normalizar_texto(buscaListaPropostas);

                                                if (proposta_atual_normalizada.includes(proposta_desejada_normalizada)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            } else {
                                                const cliente_atual_normalizado = normalizar_texto(proposal.nome_razao_cliente);
                                                const cliente_desejado_normalizado = normalizar_texto(buscaListaPropostas);

                                                if (cliente_atual_normalizado.includes(cliente_desejado_normalizado)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            }
                                        })
                                        .map(proposal => {
                                            let data_apresentacao_proposta = (new Date(proposal.data_apresentacao_proposta)).toLocaleDateString();

                                            return (
                                                <tr key={String(proposal.numero_proposta)+' '+String(proposal.revisao_proposta)}>

                                                    <td>{proposal.numero_proposta}_GR</td>
                                                    <td>{proposal.revisao_proposta}</td>
                                                    <td>{vocativo(proposal)}</td>
                                                    <td>{data_apresentacao_proposta}</td>
                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(proposal.valor_final)}</td>
                                                    <td>{proposal.nome_usuario}</td>
                                                    <td>{proposal.nome_usuario_criador}</td>

                                                    <td>
                                                        <div className="type4-functions-gr">
                                                            <button className="view-button1 botao-pagina-proposta" onClick={() => editarProposta(proposal)}>
                                                                Editar
                                                            </button>

                                                            {/* <button className="view-button2 botao-pagina-proposta" onClick={() => novaRevisao(proposal)}>
                                                                Nova revisão
                                                            </button> */}

                                                            {!permissaoUsuario.verificar(60, token.tipo_usuario) ? (
                                                                <></>
                                                            ) : !doneDeletar ? (
                                                                <button className="white-background botao-pagina-proposta">
                                                                    <DynamicLoading />
                                                                </button>
                                                            )  :  (
                                                                <button className="delete1 botao-pagina-proposta" onClick={() => {
                                                                    setPropostaExclusao(proposal);
                                                                    setModalConfirmarExclusaoAberto(true);
                                                                }}>
                                                                    <FiTrash2 size={18} />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
}