// import { checkResponse } from './authentication';

import axios from 'axios';
require('dotenv/config');

axios.defaults.withCredentials = true

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: 60000,
})

// api.interceptors.response.use(function (error) {
//     console.log('error');
//     console.log(error);

//     return Promise.reject(error);
// });


api.interceptors.response.use(
    response => {
    return response;
}, error => {
    if(error.response.status === 401) {
        // console.log("ATENÇÃO")
        localStorage.removeItem('sgc_br');
        window.location.reload();
    }
    return Promise.reject(error);
});

export default api;