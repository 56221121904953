// Referências externas
import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { Loading } from '../../../../common';

// Referências internas
const permissaoUsuario = require('../../../../services/permissaoUsuario');

export default function Resumo (props) {
    let area_total = 0;
    let peso_total = 0;

    if (props.estadoFormulario.tipo_estrutura && props.dadosRoute && props.sistema_modulo) {
        let estrutura = props.estadoFormulario.tipo_estrutura.split(' ', 1)[0];
        let correcao_area = 0;
        if (estrutura === 'Laje') {
          correcao_area = props.dadosRoute.informacao_complementar_float.correcao_area_laje.valor;
        } else if(estrutura === 'Solo') {
          correcao_area = 4;
        } else {
          correcao_area = props.dadosRoute.informacao_complementar_float.correcao_area_telhado.valor;
        }
        
        area_total = Math.ceil(correcao_area * props.sistema_modulo[0].quant * props.sistema_modulo[0].area);
        peso_total = Math.ceil(props.sistema_modulo[0].peso * props.sistema_modulo[0].quant);
    }
    
    return (
        <>
            {(props.done && props.resumoAnaliseInvalido) && <div className="resumo-invalido"> <FiAlertTriangle size={18} />  <h3> RESUMO INVÁLIDO </h3> <FiAlertTriangle size={18} />  </div>}
            {!props.done ? (
                <div>
                    <Loading />
                </div>
                    )  :  ( 
                <>
                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Sist. Determinado</th>
                                <td>{parseFloat(props.resumo.sistema_sugerido).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})} kWp</td>
                            </tr>
                            <tr>
                                <th>Geração média</th>
                                <td>{parseFloat(props.resumo.geracao_media).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})} kWh</td>
                            </tr>
                            <tr>
                                <th>Área ocupada</th>
                                <td>{parseFloat(area_total).toLocaleString('pt-BR', {style: 'decimal'})} m²</td>
                            </tr>
                            <tr>
                                <th>Peso total</th>
                                <td>{parseFloat(peso_total).toLocaleString('pt-BR', {style: 'decimal'})} kg</td>
                            </tr>
                            <tr>
                                <th>Pot. AC nominal</th>
                                <td>{parseFloat(props.resumo.potencia_total).toLocaleString('pt-BR', {style: 'decimal'})} kW</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Conta atual</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.conta_sem_sistema)}</td>
                            </tr>
                            <tr>
                                <th>Economia</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.economia)}</td>
                            </tr>
                            <tr>
                                <th>Conta com o sistema</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.conta_com_sistema)}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results2">
                        <tbody>
                            <tr className="valor_final">
                                <th>Valor Final</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_final)}</td>
                            </tr>
                            <tr>
                                <th>R$/kWp</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_final_kwp)}</td>
                            </tr>
                            <tr>
                                <th>Etapa 1</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.etapa1)}</td>
                            </tr>
                            <tr>
                                <th>Etapa 2</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.etapa2)}</td>
                            </tr>
                            <tr>
                                <th>Etapa 3</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.etapa3)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results3">
                        <tbody>
                            <tr>
                                <th>Payback</th>
                                <td colSpan="2">{props.resumo.payback_string}</td>
                            </tr>
                        </tbody>
                    </table>

                    {permissaoUsuario.verificar(47, props.token.tipo_usuario) &&
                        <table className="table-results2">
                            <tbody>
                                <tr>
                                    <th>WEG</th>
                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_produto)}</td>
                                </tr>
                                <tr>
                                    <th>Brazil Solution</th>
                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_servico)}</td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </>
            )}
            {(props.done && props.resumoAnaliseInvalido) && <div className="resumo-invalido"> <FiAlertTriangle size={18} />  <h3> RESUMO INVÁLIDO </h3> <FiAlertTriangle size={18} />  </div>}
        </>
    )
}