// Referências externas
import React, { useEffect } from 'react';
import { FiX, FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { Loading, DynamicLoading } from '../../../common';

export default function ModalEditarPropostaBranco(props) {
    
    useEffect(() => {
        props.setDadosModal({
            id_cliente_proposta: props.propostaBrancoEdicao.id_cliente_proposta,
            id_usuario_responsavel_proposta: props.propostaBrancoEdicao.id_usuario_responsavel_proposta,
            data_apresentacao_proposta: ((props.propostaBrancoEdicao.data_apresentacao_proposta).slice(0, 10)),
            numero_proposta: props.propostaBrancoEdicao.numero_proposta,
            revisao_proposta: props.propostaBrancoEdicao.revisao_proposta
        });
    }, []);

    return (
        <div className="modal-fundo">
            <div className="modal-editar-proposta-branco">
                <div className="modal-conteudo2">
                    <div className="modal-proposta-branco-titulo">
                        <h2>Editar proposta branco</h2>
                        <button className="botao-fechar-modal" onClick={() => props.fechar()} >
                            <FiX />
                        </button>
                    </div>
                    <div>
                        {props.listasCarregando ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (
                            <div className="modal-proposta-branco-corpo">
                                <table className="proposta-branco-edicao-tabela-bloqueada">
                                    <tbody>
                                        <tr>
                                            <th>
                                                N° da proposta
                                            </th>
                                            <td>
                                                {props.propostaBrancoEdicao.numero_proposta}
                                            </td>
                                            <th>
                                                Revisão
                                            </th>
                                            <td>
                                                {props.propostaBrancoEdicao.revisao_proposta}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Criador Proposta
                                            </th>
                                            <td colSpan="2">
                                                {props.propostaBrancoEdicao.nome_usuario_criador}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h4 className="modal-proposta-branco-edicao-h4" >Campos editáveis</h4>

                                <table className="proposta-branco-edicao-tabela">
                                    <tbody>
                                        <tr>
                                            <th>Data</th>
                                            <td>
                                                <input
                                                    name="data_apresentacao_proposta"
                                                    type="date"
                                                    value={props.dadosModal.data_apresentacao_proposta}
                                                    onChange={(e) => props.setDadosModal({...props.dadosModal, "data_apresentacao_proposta": e.target.value})}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Cliente</th>
                                            <td>
                                                <select value={props.dadosModal.id_cliente_proposta} name="cliente" onChange={e => { props.setDadosModal({...props.dadosModal, "id_cliente_proposta": e.target.value}); }} >
                                                    {props.clientes
                                                        .map(cliente => (
                                                            <option key={cliente.ID_cliente} value={cliente.ID_cliente}>{cliente.nome_razao_cliente}</option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Agente Comercial</th>
                                            <td>
                                                <select value={props.dadosModal.id_usuario_responsavel_proposta} name="responsavel" onChange={e => { props.setDadosModal({...props.dadosModal, "id_usuario_responsavel_proposta": e.target.value}); }} >
                                                    {props.responsaveis
                                                        .map(responsavel => (
                                                            <option key={responsavel.id_usuario} value={responsavel.id_usuario}>{responsavel.nome_usuario}</option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="modal-proposta-branco-botoes">
                                    <button className="modal-proposta-branco-cancelar" onClick={() => props.fechar()}>Cancelar</button>

                                    {props.enviandoDadosModal ? (
                                        <button disabled className="modal-proposta-branco-salvar-carregando">
                                            <div><DynamicLoading /></div>
                                        </button>
                                    ) : (
                                        <button className="modal-proposta-branco-salvar" onClick={() => props.editarPropostaBranco()}> Salvar</button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}