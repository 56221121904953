// Referências externas
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import { useCookies } from "react-cookie";
import { FiSliders, FiSettings } from 'react-icons/fi'

// Estilo
import './styles.css';

function NavItem(props) {
    const [open, setOpen] = useState(false);

    return (    
        <li className="nav-item">
            <a
                href="#"
                className="icon-button"
                onClick={() => setOpen(!open)}
            >
                <FiSettings size={18} color="#000000" />
            </a>
            
            {open && <a onClick={() => setOpen(!open)} className="background-click"></a> }

            {open && props.children}
        </li>
    );
}

function DropdownMenu() {
    const [removeCookie] = useCookies(["sessionId"]);

    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
    }, [])

    const history = useHistory();


    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    function DropdownItem(props) {
        return (
            <Link className="menu-item" to={props.destino} >
                <span className="icon-button">{props.leftIcon}</span>
                {props.children}
                <span className="icon-right">{props.rightIcon}</span>
            </Link>
        );
    }

    return (
        <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
            <CSSTransition
                in={activeMenu === 'main'}
                timeout={500}
                classNames="menu-primary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    <DropdownItem
                        leftIcon={<FiSliders size={18} color="#000000" />}
                        destino={'/painel_controle'}
                    >
                        Painel de controle
                    </DropdownItem>
                </div>
            </CSSTransition>
        </div>
    );
}

export default function Configuracoes() {
    return (
        <NavItem>
            <DropdownMenu></DropdownMenu>
        </NavItem>
    );
}