// Referências externas
import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { Loading } from '../../../../common';

export default function Resumo (props) {
    let area_total = 0;
    let peso_total = 0;

    if (props.estadoFormulario.tipo_estrutura && props.dadosRoute && props.sistema_modulo) {
        let estrutura = props.estadoFormulario.tipo_estrutura.split(' ', 1)[0];
        let correcao_area = 0;
        if (estrutura === 'Laje') {
          correcao_area = props.dadosRoute.informacao_complementar_float.correcao_area_laje.valor;
        } else if(estrutura === 'Solo') {
          correcao_area = 4;
        } else {
          correcao_area = props.dadosRoute.informacao_complementar_float.correcao_area_telhado.valor;
        }
        
        area_total = Math.ceil(correcao_area * props.sistema_modulo[0].quant * props.sistema_modulo[0].area);
        peso_total = Math.ceil(props.sistema_modulo[0].peso * props.sistema_modulo[0].quant);
    }

    return (
        <>
            {(props.done && props.resumoAnaliseInvalido) && <div className="resumo-invalido"> <FiAlertTriangle size={18} />  <h3> RESUMO INVÁLIDO </h3> <FiAlertTriangle size={18} />  </div>}
            {!props.done ? (
                <div>
                    <Loading />
                </div>
                    )  :  ( 
                <>
                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Sist. Teórico</th>
                                <td>{parseFloat(props.resumo.sistema_necessario).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})} kWp</td>
                            </tr>
                            <tr>
                                <th>Sist. Determinado</th>
                                <td>{parseFloat(props.resumo.sistema_sugerido).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})} kWp</td>
                            </tr>
                            <tr>
                                <th>Geração média</th>
                                <td>{parseFloat(props.resumo.geracao_media).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})} kWh</td>
                            </tr>
                            <tr>
                                <th>Área ocupada</th>
                                <td>{parseFloat(area_total).toLocaleString('pt-BR', {style: 'decimal'})} m²</td>
                            </tr>
                            <tr>
                                <th>Peso total</th>
                                <td>{parseFloat(peso_total).toLocaleString('pt-BR', {style: 'decimal'})} kg</td>
                            </tr>
                            <tr>
                                <th>Pot. AC nominal</th>
                                <td>{parseFloat(props.resumo.potencia_total).toLocaleString('pt-BR', {style: 'decimal'})} kW</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Conta atual</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.conta_sem_sistema)}</td>
                            </tr>
                            <tr>
                                <th>Economia</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.economia)}</td>
                            </tr>
                            <tr>
                                <th>Conta com o sistema</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.conta_com_sistema)}</td>
                            </tr>
                            <tr>
                            {/* opex_fiob_icms_mensal_medio_ano1 */}
                                <th>Fio B e ICMS mensal médio</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.opex_fiob_icms_mensal_medio_ano1)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Dias de obra</th>
                                <td>{props.resumo.dias_obra}</td>
                            </tr>
                            <tr>
                                <th>Diária mão de obra e transporte</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.mao_obra_transporte / props.resumo.dias_obra)}</td>
                            </tr>
                            <tr>
                                <th>Mão de obra e transporte</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.mao_obra_transporte)}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Sistema com frete</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.precoTotalCIF)}</td>
                            </tr>
                            <tr>
                                <th>Autotransformador</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.total_sistema_autotransformador)}</td>
                            </tr>
                            <tr>
                                <th>Mão de obra e transporte</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.mao_obra_transporte)}</td>
                            </tr>
                            <tr>
                                <th>Material</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.estadoFormulario.material_proposta * props.resumo.parcial_produto / 100)}</td>
                            </tr>
                            <tr>
                                <th>Imposto</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.estadoFormulario.imposto_proposta * (props.resumo.valor_final - props.resumo.parcial_produto) / 100)}</td>
                            </tr>
                            <tr>
                                <th>Comissão</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_comissao)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results2">
                        <tbody>
                            <tr className="destaque_ganhos">
                                <th>Margem</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.estadoFormulario.margem_proposta * props.resumo.valor_final / 100)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results2">
                        <tbody>
                            <tr>
                                <th>Verificação</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(
                                        (props.estadoFormulario.margem_proposta * props.resumo.valor_final / 100) +
                                        props.precoTotalCIF +
                                        props.resumo.mao_obra_transporte +
                                        (props.estadoFormulario.material_proposta * props.resumo.parcial_produto / 100) +
                                        (props.estadoFormulario.imposto_proposta * (props.resumo.valor_final - props.resumo.parcial_produto) / 100) +
                                        props.resumo.valor_comissao +
                                        props.total_sistema_autotransformador
                                    )}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results2">
                        <tbody>
                            <tr className="valor_final">
                                <th>Valor Final</th>
                                <th>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_final)}</th>
                            </tr>
                            <tr>
                                <th>R$/kWp</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_final_kwp)}</td>
                            </tr>
                            <tr>
                                <th>Etapa 1</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.etapa1)}</td>
                            </tr>
                            <tr>
                                <th>Etapa 2</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.etapa2)}</td>
                            </tr>
                            <tr>
                                <th>Etapa 3</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.etapa3)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results3">
                        <tbody>
                            <tr>
                                <th>Payback</th>
                                <td colSpan="2">{props.resumo.payback_string}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results2">
                        <tbody>
                            <tr>
                                <th colSpan="2">WEG</th>
                            </tr>
                            <tr>
                                <th>Produto</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_produto)}</td>
                            </tr>
                            <tr>
                                <th>Produto/kWp</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_produto_kwp)}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results2">
                        <tbody>
                            <tr>
                                <th colSpan="2">Brazil Solution</th>
                            </tr>
                            <tr>
                                <th>Serviço</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_servico)}</td>
                            </tr>
                            <tr>
                                <th>Serviço/kWp</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.parcial_servico_kwp)}</td>
                            </tr>
                        </tbody>
                    </table>

                </>
            )}
            {(props.done && props.resumoAnaliseInvalido) && <div className="resumo-invalido"> <FiAlertTriangle size={18} />  <h3> RESUMO INVÁLIDO </h3> <FiAlertTriangle size={18} />  </div>}
        </>
    )
}