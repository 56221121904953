// Referências externas
import React, { useEffect, useState, useRef } from 'react';
import { FiX } from 'react-icons/fi';

// Referências internas
import { DynamicLoading } from '../../../common';

// Estilo
import '../styles.css';

export default function (props) {
    const [dadosForm, setDadosForm] = useState({});
    const [array, setArray] = useState([]);

    const mounted = useRef();

    function salvarDadosForm(e) {
        e.preventDefault();

        const dadosFormFormatado = Object.keys(dadosForm).map(x => {
            return [x, dadosForm[x][1], dadosForm[x][0]]
        })

        props.editarInformacoesComuns(dadosFormFormatado);
    }

    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            setDadosForm(
                Object.assign({}, ...props.informacaoComum.map(x => ({
                    [x.chave]: [x.unidade, x.valor]
                })))
            );

            setArray(props.informacaoComum.map(x => x.chave))

            mounted.current = true;
        }        
    });

    return (
        <div className="modal-fundo">
            <div className="modal-form">
                <div className="modal-conteudo2">
                    <div className="modal-header">
                        <h2 className="wrapper-titulo">Editar informações comuns</h2>
                        <button className="botao-fechar-modal" onClick={() => props.fecharModal()} >
                            <FiX />
                        </button>
                    </div>
                    <div>
                        <div>
                            <form >
                                <div className="modal-corpo-editar-informacoes-comuns">
                                    {array.map(x => {
                                        return (
                                            <div key={x} className="input-group-margem-reduzida">
                                                <label htmlFor="nome_razao_cliente" className="label-client-text">
                                                    {dadosForm[x][0]}
                                                </label>
                                                <input
                                                    className="modal-cliente-input"
                                                    type="number"
                                                    id="nome_razao_cliente"
                                                    name={x}
                                                    value={dadosForm[x][1]}
                                                    onChange={e => {
                                                        setDadosForm({
                                                            ...dadosForm,
                                                            [(x)]: [dadosForm[x][0], parseFloat(e.target.value)]
                                                        })
                                                    }
                                                }
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="modal-editar-informacoes-comuns-wrapper-botoes">
                                    <button className="button-cancel" type="button" onClick={() => props.fecharModal()}> Cancelar </button>
                                    
                                    {props.doneEditarInformacoesComuns ? (
                                        <button className="button-submit" type="submit" onClick={(e) => salvarDadosForm(e)}> Salvar </button>
                                    )  :  (
                                        <button className="button-carregando" >
                                            <DynamicLoading />
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}