function verificar(nivel, tipo_usuario) {
    let saida = false;
    switch (nivel) {
        case 5:
            (
                tipo_usuario === 'administrador' ||
                tipo_usuario === 'desenvolvedor' ||
                tipo_usuario === 'suportevendas1' ||
                tipo_usuario === 'suportevendas2' ||
                tipo_usuario === 'rep-weg') ? saida = true : saida = false;
            break;
            
        case 10:
            (
                tipo_usuario === 'administrador' ||
                tipo_usuario === 'desenvolvedor' ||
                tipo_usuario === 'suportevendas1' ||
                tipo_usuario === 'suportevendas2' ||
                tipo_usuario === 'agente4' ||
                tipo_usuario === 'agente3' ||
                tipo_usuario === 'agente2' ||
                tipo_usuario === 'agente1') ? saida = true : saida = false;
            break;

        case 30:
            (
                tipo_usuario === 'administrador' ||
                tipo_usuario === 'desenvolvedor' ||
                tipo_usuario === 'suportevendas1' ||
                tipo_usuario === 'suportevendas2' ||
                tipo_usuario === 'agente4' ||
                tipo_usuario === 'agente3') ? saida = true : saida = false;
            break;

        case 40:
            (
                tipo_usuario === 'administrador' ||
                tipo_usuario === 'desenvolvedor' ||
                tipo_usuario === 'suportevendas1' ||
                tipo_usuario === 'suportevendas2' ||
                tipo_usuario === 'agente4') ? saida = true : saida = false;
            break;
            
        case 45:
            (
                tipo_usuario === 'administrador' ||
                tipo_usuario === 'desenvolvedor' ||
                tipo_usuario === 'suportevendas1' ||
                tipo_usuario === 'suportevendas2') ? saida = true : saida = false;
            break;
            
        case 47:
            (
                tipo_usuario === 'administrador' ||
                tipo_usuario === 'desenvolvedor' ||
                tipo_usuario === 'suportevendas2') ? saida = true : saida = false;
            break;

        case 50:
            (tipo_usuario === 'administrador' || tipo_usuario === 'desenvolvedor') ? saida = true : saida = false;
            break;

        case 60:
            (tipo_usuario === 'desenvolvedor') ? saida = true : saida = false;
            break;
    
        default:
            break;
    }

    return saida;
}

module.exports = { verificar };