// Referências externas
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiEye, FiEyeOff, FiAlertTriangle } from 'react-icons/fi'

// Referências internas
import { DynamicLoading } from '../../common';
import api from '../../services/api';
import validarEstado from './validarEstado';

// Estilo
import './styles.css';
import background from '../../assets/AdobeStock_4.jpeg';
import logo from '../../assets/logo-branco.png';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');
const authentication = require('../../services/authentication');

export default function Login() {
    const history = useHistory();
    
    const [senhaVisivel, setSenhaVisivel] = useState(false);
    const refCampoSenha = useRef(null);
    
    const [token, setToken] = useState({});

    const [erros, setErros] = useState({});
    const [done, setDone] = useState(true);
    const [email_usuario, setEmail_usuario] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const token = JSON.parse(localStorage.getItem('sgc_br'));

        if (authentication.checkAuth() === true) {
            if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
                history.push('/inicio');
            } else if (permissaoUsuario.verificar(10, token.tipo_usuario)) {
                history.push('/clientes');
            } else {
                history.push('/sistemas');
            }
        }
        
        refCampoSenha.current.selectionStart = refCampoSenha.current.value.length;
        refCampoSenha.current.selectionEnd = refCampoSenha.current.value.length;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senhaVisivel]);

    function alternarVisibilidadeSenha(e) {
        e.preventDefault();

        setSenhaVisivel(!senhaVisivel);

        refCampoSenha.current.focus();
    }

    const { register, handleSubmit } = useForm();

    const  onSubmit = (data) => {
        setSenhaVisivel(false);
        handleLogin();
    }

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarCredencialIncorreta = () => {
        toast.warn('E-mail ou senha incorretos!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function handleLogin() {
        const objetoErros = validarEstado(email_usuario, password);
        setErros(objetoErros);

        if (Object.keys(objetoErros).length === 0) {
            setDone(false);

            const send = {
                "email_usuario": email_usuario,
                "password": password
            };

            try {
                const response = await api.post('login', send);
                if (response.data.status === "Usuário autenticado") {
                    const currentDate = new Date();

                    // const expiration = currentDate + (1000 * 60 * process.env.SESSION_MINUTES);
                    // const expiration = currentDate.getTime() + (1000 * 60 * 30);
                    const expiration = currentDate.getTime() + (1000 * 60 * 180);
                
                    const tokenJSON = {
                        "email_usuario": send.email_usuario,
                        "expiration": expiration,
                        "imagem_usuario": response.data.imagem_usuario,
                        "tipo_usuario": response.data.tipo_usuario
                    }
                
                    localStorage.setItem('sgc_br', JSON.stringify(tokenJSON));

                    if (permissaoUsuario.verificar(50, response.data.tipo_usuario)) {
                        history.push('/inicio');
                    } else if (permissaoUsuario.verificar(10, response.data.tipo_usuario)) {
                        history.push('/clientes');
                    } else {
                        history.push('/sistemas');
                    }
                } else {
                    notificarCredencialIncorreta();
                    setDone(true);
                }

            } catch (error) {
                notificarErro();
                setDone(true);
            }

        }
    }

    return (

        <div className="login-container">
            <ToastContainer />
            <div
                className="login-background"
                style={{
                    background: 'url('+ background +'), linear-gradient(135deg, rgba(0, 109, 191, 0.90), rgba(0, 99, 201, 0.60))',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundBlendMode: 'overlay'
                }}
            >
                <div className="login-logo-container">
                    <img src={logo}  alt="BRAZIL SOLUTION"/>
                </div>
            </div>

            <div className="login-form-wrapper">
                
                <div className="login-form">

                    <h3>Login</h3>

                    <form onSubmit={ handleSubmit(onSubmit) } >

                        <div className="login-form-fields">
                            <div className="">
                                <label htmlFor="email_usuario">E-mail {erros.email_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}</label>
                                <input
                                    autoFocus
                                    type={senhaVisivel ? 'text' : 'text'}
                                    id="email_usuario"
                                    name="email_usuario"
                                    placeholder="Digite seu e-mail"
                                    value={email_usuario}
                                    onChange={e => {setEmail_usuario(e.target.value);}}
                                />
                            </div>

                            <div>
                                <label htmlFor="Senha">Senha {erros.password && <FiAlertTriangle size={12} color="#ff0000"/>}</label>
                                <div className="input_senha">
                                    <input
                                        type={senhaVisivel ? "text" : "password"}
                                        id="Senha"
                                        name="Senha"
                                        placeholder="Digite sua senha"
                                        value={password}
                                        onChange={e => {setPassword(e.target.value);}}
                                        ref={refCampoSenha}
                                    />
                                    <button className="alterna_visibilidade" type="button" onClick={e => alternarVisibilidadeSenha(e)}>
                                        {senhaVisivel ? (<FiEye size={12}/>) : (<FiEyeOff size={12}/>)}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!done ? (
                            <button className="botao-entrar" disabled={!done} type="submit">
                                <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }} >
                                    Carregando
                                    <div style={{height: '35px', width: '35px'}} >
                                        <DynamicLoading />
                                        
                                    </div>
                                </div>
                            </button>
                        )  :  (
                            <button className="botao-entrar" type="submit">
                                <div> Entrar </div>
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}