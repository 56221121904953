// Referências externas
import React, {useState, useEffect} from 'react';
import { FiRotateCw, FiAlertTriangle } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Referências internas
import validar from './validarEstado';
import useForm from './useForm';
import '../../styles.css';
import { Loading } from '../../../../common';

// Referências internas
const permissaoUsuario = require('../../../../services/permissaoUsuario');

const Form = ( props ) => {
  const {
    erros,
    alteracao_desconto_WEG,
    alteracao_concessionaria,
    alteracao_responsavel,
    alteracao_estrutura,
    alteracao_modulo,
    alteracao_media,
    alteracao_mes_consumo,
    setValueObject,
    handleChange,
    handleSubmit} = useForm(
      props.notificarErroPreenchimentoFormulario,
      props.estadoFormulario,
      props.setEstadoFormulario,
      props.restringirBotoes,
      props.gerarProposta,
      props.dadosRoute,
      validar,
      ajustarNovaLista
  );

  const [concessionarias_validas, setConcessionarias_validas] = useState([]);
  const [responsaveis_validos, setResponsaveis_validos] = useState([]);
  const [modulos_validos, setModulos_validos] = useState([]);
  const [estruturas_validas, setEstruturas_validas] = useState([]);

  function ajustarNovaLista() {
    if (props.propostaExistente.tabela_considerada && props.dadosRoute.tabela_vigente.chave !== props.propostaExistente.tabela_considerada) {
      setValueObject({
        modulo_modelo: props.dadosRoute.tabela_vigente.modulo_preferencial.modelo,
        modulo_potencia: props.dadosRoute.tabela_vigente.modulo_preferencial.potencia,
        modulo_area: props.dadosRoute.tabela_vigente.modulo_preferencial.area,
        modulo_peso: props.dadosRoute.tabela_vigente.modulo_preferencial.peso,
        modulo_garantia1: props.dadosRoute.tabela_vigente.modulo_preferencial.garantia1,
        modulo_garantia2: props.dadosRoute.tabela_vigente.modulo_preferencial.garantia2,
        modulo_campo_adicional1: props.dadosRoute.tabela_vigente.modulo_preferencial.campo_adicional1,
      })
    }
  }
  
  function verificar_proposta_existente() {
    if (Object.keys(props.propostaExistente).length > 0) {

      setValueObject({
        concessionaria: props.propostaExistente.concessionaria_proposta,
        responsavel: props.propostaExistente.nome_usuario,
        id_responsavel: props.propostaExistente.id_usuario_responsavel_proposta,
        comissao: Math.round(props.propostaExistente.comissao_proposta * 100) / 100,
        percentual_atendido: Math.round(props.propostaExistente.percentual_atendido * 100) / 100,
        tarifa: Math.round(props.propostaExistente.tarifa_proposta * 100) / 100,
        valor_fixo: Math.round(props.propostaExistente.valor_fixo * 100) / 100,
        injecao: Math.round(props.propostaExistente.injecao * 100) / 100,
        icms_tarifa: Math.round(props.propostaExistente.icms_tarifa * 100) / 100,
        parcela_tusd: Math.round(props.propostaExistente.parcela_tusd * 100) / 100,
        parcela_fiob: Math.round(props.propostaExistente.parcela_fiob * 100) / 100,
        aplicacao_estrutura: props.propostaExistente.aplicacao_estrutura,
        tipo_estrutura: props.propostaExistente.tipo_estrutura,
        modulo_modelo: props.propostaExistente.sistema_modulo[0].modelo,
        modulo_potencia: Math.round(props.propostaExistente.sistema_modulo[0].potencia * 100) / 100,
        modulo_area: props.propostaExistente.sistema_modulo[0].area,
        modulo_peso: Math.round(props.propostaExistente.sistema_modulo[0].peso * 100) / 100,
        modulo_garantia1: props.propostaExistente.sistema_modulo[0].garantia1,
        modulo_garantia2: props.propostaExistente.sistema_modulo[0].garantia2,
        modulo_campo_adicional1: props.propostaExistente.sistema_modulo[0].campo_adicional1,
        desconto_weg_proposta: props.propostaExistente.desconto_weg_proposta.map(x => (Math.round(x * 100) / 100)),
        consumo_medio: Math.round((props.propostaExistente.consumo.reduce((acc, cur) => acc + cur)) / 12),
        curva_consumo: props.propostaExistente.consumo,
        consumo_janeiro: Math.round(props.propostaExistente.consumo[0] * 100) / 100,
        consumo_fevereiro: Math.round(props.propostaExistente.consumo[1] * 100) / 100,
        consumo_marco: Math.round(props.propostaExistente.consumo[2] * 100) / 100,
        consumo_abril: Math.round(props.propostaExistente.consumo[3] * 100) / 100,
        consumo_maio: Math.round(props.propostaExistente.consumo[4] * 100) / 100,
        consumo_junho: Math.round(props.propostaExistente.consumo[5] * 100) / 100,
        consumo_julho: Math.round(props.propostaExistente.consumo[6] * 100) / 100,
        consumo_agosto: Math.round(props.propostaExistente.consumo[7] * 100) / 100,
        consumo_setembro: Math.round(props.propostaExistente.consumo[8] * 100) / 100,
        consumo_outubro: Math.round(props.propostaExistente.consumo[9] * 100) / 100,
        consumo_novembro: Math.round(props.propostaExistente.consumo[10] * 100) / 100,
        consumo_dezembro: Math.round(props.propostaExistente.consumo[11] * 100) / 100,
        relacao_geracao: Math.round(props.propostaExistente.relacao_geracao * 100) / 100,
        curva_geracao: props.propostaExistente.geracao,
        tipo_inversor: props.propostaExistente.tipo_inversor,
        status_proposta: props.propostaExistente.status_proposta
      });
    };
  }
  
  useEffect(() => {
    if (Object.keys(props.dadosRoute).length > 0) {
      setModulos_validos(props.dadosRoute.tabela_vigente.modulos);

      setConcessionarias_validas(props.dadosRoute.concessionarias);
      props.token.tipo_usuario === 'suportevendas1' && setResponsaveis_validos(props.dadosRoute.responsaveis);
      setEstruturas_validas(Object.values(props.dadosRoute.tabela_vigente.estruturas));

      let array_consumo = Object.values(props.dadosRoute.curvas.consumo1);
      let array_geracao = Object.values(props.dadosRoute.curvas.geracao1);
      
      setValueObject({
        valor_fixo: Math.round(props.dadosRoute.informacao_complementar_float.valor_fixo_padrao.valor * 100) / 100,
        modulo_modelo: props.dadosRoute.tabela_vigente.modulo_preferencial.modelo,
        modulo_potencia: Math.round(props.dadosRoute.tabela_vigente.modulo_preferencial.potencia * 100) / 100,
        modulo_area: props.dadosRoute.tabela_vigente.modulo_preferencial.area,
        modulo_peso: Math.round(props.dadosRoute.tabela_vigente.modulo_preferencial.peso * 100) / 100,
        modulo_garantia1: props.dadosRoute.tabela_vigente.modulo_preferencial.garantia1,
        modulo_garantia2: props.dadosRoute.tabela_vigente.modulo_preferencial.garantia2,
        modulo_campo_adicional1: props.dadosRoute.tabela_vigente.modulo_preferencial.campo_adicional1,
        desconto_weg_proposta: props.dadosRoute.desconto_weg.map(x => (Math.round(x * 100) / 100)),
        curva_consumo: array_consumo,
        consumo_medio: Math.round( ( (array_consumo.reduce((acc, cur) => acc + cur)) / 12) ),
        consumo_janeiro: Math.round(array_consumo[0] * 100) / 100,
        consumo_fevereiro: Math.round(array_consumo[1] * 100) / 100,
        consumo_marco: Math.round(array_consumo[2] * 100) / 100,
        consumo_abril: Math.round(array_consumo[3] * 100) / 100,
        consumo_maio: Math.round(array_consumo[4] * 100) / 100,
        consumo_junho: Math.round(array_consumo[5] * 100) / 100,
        consumo_julho: Math.round(array_consumo[6] * 100) / 100,
        consumo_agosto: Math.round(array_consumo[7] * 100) / 100,
        consumo_setembro: Math.round(array_consumo[8] * 100) / 100,
        consumo_outubro: Math.round(array_consumo[9] * 100) / 100,
        consumo_novembro: Math.round(array_consumo[10] * 100) / 100,
        consumo_dezembro: Math.round(array_consumo[11] * 100) / 100,
        relacao_geracao: Math.round(props.dadosRoute.informacao_complementar_float.relacao_geracao_padrao.valor * 100) / 100,
        curva_geracao: array_geracao,
      });
    }

    verificar_proposta_existente();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataRoute, props.propostaExistente, props.tabelaUtilizada])

  return (
      <form onSubmit={handleSubmit}>
          <Tabs>
            <TabList>
              <Tab>Geral {erros.geral && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
              <Tab>{erros.comercial && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
              <div style={{width: '30%'}}></div>
            </TabList>

            <div className="proposal-subelement">
              <div className="proposal-element-body">

                <TabPanel>
                    <div className="input-group-2">
                      <label htmlFor="aplicacao_estrutura">
                        Tipo de estrutura {erros.aplicacao_estrutura && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="aplicacao_estrutura"
                        name="aplicacao_estrutura"
                        value={props.estadoFormulario.aplicacao_estrutura}
                        onChange={e => { alteracao_estrutura(e, estruturas_validas); }} >
                          <option value =''>Selecione</option>

                          {estruturas_validas.map(estrutura => (
                              <option key = {estrutura.aplicacao_estrutura} value = {estrutura.aplicacao_estrutura}>{estrutura.aplicacao_estrutura}</option>
                          ))}

                      </select>
                    </div>


                    
                    
                    <div className="input-group-2">
                      <label htmlFor="modulo_modelo">
                        Módulo {erros.modulo_modelo && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="modulo_modelo"
                        name="modulo_modelo"
                        value={props.estadoFormulario.modulo_modelo}
                        onChange={e => { alteracao_modulo(e, modulos_validos); }}
                      >
                        <option value ='' >Selecione</option>

                        {modulos_validos && modulos_validos
                          .map(modulo => (
                              <option key = {modulo.modelo} value = {modulo.modelo}>{modulo.modelo}</option>
                        ))}

                      </select>
                    </div>




                    
                    <div className="input-group-2">
                      <label htmlFor="concessionaria">
                        Concessionária {erros.concessionaria && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="concessionaria"
                        name="concessionaria"
                        value={props.estadoFormulario.concessionaria}
                        onChange={e => { alteracao_concessionaria(e, concessionarias_validas); }} >
                          <option value ='' >Selecione</option>

                          {concessionarias_validas.map(concessionaria => (
                              <option key = {concessionaria.nome_concessionaria} value = {concessionaria.nome_concessionaria}>{concessionaria.nome_concessionaria}</option>
                          ))} 

                      </select>
                    </div>
                    
                    {props.token.tipo_usuario === 'suportevendas1' && (
                      <div className="input-group-2">
                        <label htmlFor="responsavel">
                          Agente Comercial {erros.responsavel && <FiAlertTriangle size={12} color="#ff0000"/>}
                        </label>
                        <select
                          id="responsavel"
                          name="responsavel"
                          value={props.estadoFormulario.responsavel}
                          onChange={e => { alteracao_responsavel(e, responsaveis_validos); }}>
                            <option value ='' >Selecione</option>

                            {responsaveis_validos.map(responsavel => (
                                <option key = {responsavel.nome_usuario} value = {responsavel.nome_usuario}>{responsavel.nome_usuario}</option>
                            ))}

                        </select>
                      </div>
                    )}

                    <div className="input-group-narrow-2">
                      <label htmlFor="tarifa" className='form-label'>
                        Tarifa (R$/kWh) {erros.tarifa && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.tarifa}
                        type='number'
                        min="0"
                        max='2'
                        step="0.00001"
                        name='tarifa'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="input-group-narrow-2-right">
                      <label htmlFor="valor_fixo" className='form-label'>
                        Ilum. púb. (R$) {erros.valor_fixo && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.valor_fixo}
                        type='number'
                        min="0"
                        step="0.01"
                        name='valor_fixo'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>

                    <div className="input-group-2">
                      <label htmlFor="relacao_geracao">
                        Relação geração (kWh/kWp) {erros.relacao_geracao && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>

                      {(permissaoUsuario.verificar(50, props.token.tipo_usuario) || props.token.tipo_usuario === 'agente4' || props.token.tipo_usuario === 'suportevendas1') ? (
                        <input
                          value={props.estadoFormulario.relacao_geracao}
                          type='number'
                          min="30"
                          max="150"
                          step="0.01"
                          name='relacao_geracao'
                          onChange={e => {handleChange(e);}}
                        />
                      ) : (
                        <input
                          disabled
                          value={props.estadoFormulario.relacao_geracao}
                          type='number'
                          min="30"
                          max="150"
                          step="0.01"
                          name='relacao_geracao'
                        />
                      )}
                    </div>

                    <div className="input-group-narrow-2">
                      <label htmlFor="tipo_inversor">
                        Tipo inversor {erros.tipo_inversor && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="tipo_inversor"
                        name="tipo_inversor"
                        value={props.estadoFormulario.tipo_inversor}
                        onChange={e => { setValueObject({tipo_inversor: e.target.value}); }} >
                          <option value ='String'>String</option>
                          {/* <option value ='Microinversor'>Microinversor</option> */}
                      </select>
                    </div>

                    {permissaoUsuario.verificar(47, props.token.tipo_usuario) && (
                      <div className="form-simulation-desconto-weg">
                        <label htmlFor="desconto_weg_proposta" className='form-label'>
                          Desconto WEG (%) {erros.desconto_weg_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                        </label>
                        <div className="form-simulation-desconto-weg-input">
                          <div>
                            <input
                              disabled
                              value={props.estadoFormulario.desconto_weg_proposta[0]}
                              type='number'
                              min="0"
                              max='100'
                              step="0.1"
                              name='desconto_weg_proposta[0]'
                              onChange={e => {alteracao_desconto_WEG(e, 0);}}
                            />
                          </div>
                          <div>
                            <input
                              disabled
                              value={props.estadoFormulario.desconto_weg_proposta[1]}
                              type='number'
                              min="0"
                              max='100'
                              step="0.1"
                              name='desconto_weg_proposta[1]'
                              onChange={e => {alteracao_desconto_WEG(e, 1);}}
                            />
                          </div>
                          <div>
                            <input
                              disabled
                              value={props.estadoFormulario.desconto_weg_proposta[2]}
                              type='number'
                              min="0"
                              max='100'
                              step="0.1"
                              name='desconto_weg_proposta[2]'
                              onChange={e => {alteracao_desconto_WEG(e, 2);}}
                            />
                          </div>
                          <div>
                            <input
                              value={props.estadoFormulario.desconto_weg_proposta[3]}
                              type='number'
                              min="0"
                              max='22'
                              step="0.1"
                              name='desconto_weg_proposta[3]'
                              onChange={e => {alteracao_desconto_WEG(e, 3);}}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <p className="secao-formulario">
                      CONSUMO
                    </p>

                    <div className="input-group-2">
                      <label htmlFor="percentual_atendido" className='form-label'>
                        Consumo atendido pelo sistema (%) {erros.percentual_atendido && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.percentual_atendido}
                        type='number'
                        min="0.1"
                        step="0.1"
                        name='percentual_atendido'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="input-group-2">
                      <label htmlFor="consumo_medio">
                        Média de consumo {erros.consumo_medio && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.consumo_medio}
                        type='number'
                        min="1"
                        step="1"
                        name='consumo_medio'
                        onChange={e => {alteracao_media(e);}}
                      />
                    </div>




                        
                    <div className="wrapper-coluna-campos">
                      <div className="coluna-campos">





                        <div className="input-group-2">
                          <label htmlFor="consumo_janeiro">
                            Janeiro {erros.consumo_janeiro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_janeiro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_janeiro'
                            onChange={e => {alteracao_mes_consumo(e, 0);}}
                          />
                        </div>
                        
                        <div className="input-group-2">
                          <label htmlFor="consumo_fevereiro">
                            Fevereiro {erros.consumo_fevereiro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_fevereiro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_fevereiro'
                            onChange={e => {alteracao_mes_consumo(e, 1);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_marco">
                            Março {erros.consumo_marco && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_marco}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_marco'
                            onChange={e => {alteracao_mes_consumo(e, 2);}}
                          />
                        </div>
                        
                        <div className="input-group-2">
                          <label htmlFor="consumo_abril">
                            Abril {erros.consumo_abril && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_abril}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_abril'
                            onChange={e => {alteracao_mes_consumo(e, 3);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_maio">
                            Maio {erros.consumo_maio && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_maio}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_maio'
                            onChange={e => {alteracao_mes_consumo(e, 4);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_junho">
                            Junho {erros.consumo_junho && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_junho}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_junho'
                            onChange={e => {alteracao_mes_consumo(e, 5);}}
                          />
                        </div>



                      </div>



                        
                      <div className="coluna-campos">




                        <div className="input-group-2">
                          <label htmlFor="consumo_julho">
                            Julho {erros.consumo_julho && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_julho}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_julho'
                            onChange={e => {alteracao_mes_consumo(e, 6);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_agosto">
                            Agosto {erros.consumo_agosto && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_agosto}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_agosto'
                            onChange={e => {alteracao_mes_consumo(e, 7);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_setembro">
                            Setembro {erros.consumo_setembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_setembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_setembro'
                            onChange={e => {alteracao_mes_consumo(e, 8);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_outubro">
                            Outubro {erros.consumo_outubro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_outubro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_outubro'
                            onChange={e => {alteracao_mes_consumo(e, 9);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_novembro">
                            Novembro {erros.consumo_novembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_novembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_novembro'
                            onChange={e => {alteracao_mes_consumo(e, 10);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_dezembro">
                            Dezembro {erros.consumo_dezembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_dezembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_dezembro'
                            onChange={e => {alteracao_mes_consumo(e, 11);}}
                          />
                        </div>


                      </div>
                    </div>


                </TabPanel>
              
                <TabPanel>
                  <div className="input-group-2">
                    <label htmlFor="comissao" className='form-label'>
                      Comissão (%) {erros.comissao && <FiAlertTriangle size={12} color="#ff0000"/>}
                    </label>
                    <input
                      value={props.estadoFormulario.comissao}
                      type='number'
                      min="0"
                      max='25'
                      step="0.1"
                      name='comissao'
                      onChange={e => {handleChange(e);}}
                    />
                  </div>

                  
                  {!props.done ? (
                    <div>
                      <Loading />
                    </div>
                  )  :  ( 
                    <>
                      <table className="table-results2">
                        <tbody>
                            <tr className="destaque_ganhos">
                                <th>Comissão</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_comissao)}</td>
                            </tr>
                        </tbody>
                      </table>
                    </>
                    )
                  }
                </TabPanel>
              
                <button className="submit-input" type="submit"> <div> <p>Gerar Proposta</p> <FiRotateCw/> </div> </button>                       
                
              </div>
            </div>
          
        </Tabs>
      </form>
  );
};

export default Form;