// Referências externas
import React, { useEffect, useState, useRef } from 'react';
import { FiX, FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { DynamicLoading } from '../../../common';
import generic_user from '../../../assets/users/generic_user.png';

// Estilo
import '../styles.css';

export default function (props) {
    const mounted = useRef();

    const [errosForm, setErrosForm] = useState({
        nome_usuario: false,
        assinatura_usuario: false,
        imagem_usuario: false,
        email_usuario: false,
        chave_usuario: false,
        confirmacao_chave_usuario: false,
        tipo_usuario: false,
        titulo_usuario: false,
        comissao_usuario: false,
        nome_usuario_superior: false,
        comissao_superior: false,
        telefone1_usuario: false,
        telefone2_usuario: false,
    });

    const [dadosForm, setDadosForm] = useState({
        nome_usuario: "9",
        assinatura_usuario: "Guilherme Souza da Rosa",
        imagem_usuario: "generic_user",
        email_usuario: "@brazilsolution.com.br",
        chave_usuario: "",
        confirmacao_chave_usuario: "",
        tipo_usuario: "agente1",
        titulo_usuario: "Agente comercial",
        comissao_usuario: 0,
        nome_usuario_superior: "andre",
        comissao_superior: 0,
        telefone1_usuario: "(21) ",
        telefone2_usuario: "(21) 3678-3597"
    });

    const [tituloUsuarioValidos, setTituloUsuarioValidos] = useState([]);
    const [senhaVisivel, setSenhaVisivel] = useState(false);


    const [previsualizacao, setPrevisualizacao] = useState();
    const [erro, setErro] = useState();

    function handleImagem(e) {
        const selected = e.target.files[0];
        const tipos_permitidos = ["image/png", "image/jpeg", "image/jpg"];
        if (selected && tipos_permitidos.includes(selected.type)) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setPrevisualizacao(reader.result);
            }
            reader.readAsDataURL(selected);
        } else {
            setErro(true);
        }
    }


    function salvarNovoCliente(e) {
        e.preventDefault();

        let verificacao_erros = {};

        if (dadosForm.nome_usuario !== '0') { verificacao_erros.nome_usuario = true }
        if (dadosForm.assinatura_usuario.length === 0) { verificacao_erros.assinatura_usuario = true }
        if (dadosForm.imagem_usuario.length === 0) { verificacao_erros.imagem_usuario = true }
        if (dadosForm.email_usuario.length === 0) { verificacao_erros.email_usuario = true }
        if (dadosForm.chave_usuario.length === 0 || dadosForm.chave_usuario !== dadosForm.confirmacao_chave_usuario) { verificacao_erros.chave_usuario = true }
        if (dadosForm.confirmacao_chave_usuario.length === 0 || dadosForm.chave_usuario !== dadosForm.confirmacao_chave_usuario) { verificacao_erros.confirmacao_chave_usuario = true }
        if (dadosForm.titulo_usuario.length === 0) { verificacao_erros.titulo_usuario = true }
        if (dadosForm.tipo_usuario.length === 0) { verificacao_erros.tipo_usuario = true }
        if (dadosForm.comissao_usuario.length === 0) { verificacao_erros.comissao_usuario = true }
        if (dadosForm.nome_usuario_superior.length === 0) { verificacao_erros.nome_usuario_superior = true }
        if (dadosForm.comissao_superior.length === 0) { verificacao_erros.comissao_superior = true }
        if (dadosForm.telefone1_usuario.length === 0) { verificacao_erros.telefone1_usuario = true }
        if (dadosForm.telefone2_usuario.length === 0) { verificacao_erros.telefone2_usuario = true }

        setErrosForm(verificacao_erros);

        if(Object.keys(verificacao_erros).length === 0) {
            props.salvarNovoUsuario(dadosForm);
        }

        // const dadosFormFormatado = Object.keys(dadosForm).map(x => {
        //     return [x, dadosForm[x][1], dadosForm[x][0]]
        // })

        // props.editarInformacoesComuns(dadosFormFormatado);
    }

    function handleChange(e) {
        setDadosForm({
            ...dadosForm,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            // setDadosForm(
            //     Object.assign({}, ...props.informacaoComum.map(x => ({
            //         [x.chave]: [x.unidade, x.valor]
            //     })))
            // );

            // setArray(props.informacaoComum.map(x => x.chave))

            setTituloUsuarioValidos(["agente1", "agente2", "agente3", "agente4", "desenvolvedor", "suportevendas1", "suportevendas2", "rep-weg", "administrador"])

            mounted.current = true;
        }        
    });

    return (
        <div className="modal-fundo">
            <div className="modal-form-2">
                <div className="modal-conteudo2">
                    <div className="modal-header">
                        <h2 className="wrapper-titulo">Cadastrar novo usuário</h2>
                        <button className="botao-fechar-modal" onClick={() => props.fecharModal()} >
                            <FiX />
                        </button>
                    </div>
                    <div >
                        <form className="modal-cadastrar-novo-usuario">
                            <div className="modal-cadastrar-novo-usuario-bloco1">
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Nome usuário {errosForm.nome_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="text"
                                        id="nome_usuario"
                                        name="nome_usuario"
                                        value={dadosForm.nome_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Assinatura usuário {errosForm.assinatura_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        disabled
                                        className="modal-cliente-input"
                                        type="text"
                                        id="assinatura_usuario"
                                        name="assinatura_usuario"
                                        value={dadosForm.assinatura_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        E-mail usuário {errosForm.email_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="text"
                                        id="email_usuario"
                                        name="email_usuario"
                                        value={dadosForm.email_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Senha {errosForm.chave_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="text"
                                        id="chave_usuario"
                                        name="chave_usuario"
                                        value={dadosForm.chave_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Confirmação senha {errosForm.confirmacao_chave_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="text"
                                        id="confirmacao_chave_usuario"
                                        name="confirmacao_chave_usuario"
                                        value={dadosForm.confirmacao_chave_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Título usuário {errosForm.titulo_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>

                                    <select
                                        className="modal-cliente-input"
                                        id="titulo_usuario"
                                        name="titulo_usuario"
                                        value={dadosForm.titulo_usuario}
                                        onChange={e => handleChange(e)}
                                    >
                                        {tituloUsuarioValidos.map(x => {
                                            return <option key={x} value={x}> {x} </option>
                                        })}
                                    </select>
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Comissão usuário {errosForm.comissao_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="number"
                                        id="comissao_usuario"
                                        name="comissao_usuario"
                                        value={dadosForm.comissao_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Usuário superior {errosForm.nome_usuario_superior && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <select
                                        className="modal-cliente-input"
                                        id="nome_usuario_superior"
                                        name="nome_usuario_superior"
                                        value={dadosForm.nome_usuario_superior}
                                        onChange={e => handleChange(e)}
                                    >
                                        <option value="andre"> andre </option>
                                        <option value="helton"> helton </option>
                                    </select>
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Comissão superior {errosForm.comissao_superior && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="number"
                                        id="comissao_superior"
                                        name="comissao_superior"
                                        value={dadosForm.comissao_superior}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Telefone 1 {errosForm.telefone1_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="text"
                                        id="telefone1_usuario"
                                        name="telefone1_usuario"
                                        value={dadosForm.telefone1_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>
                                <div className="input-group-margem-reduzida">
                                    <label htmlFor="nome_razao_cliente" className="label-client-text">
                                        Telefone 2 {errosForm.telefone2_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
                                    </label>
                                    <input
                                        className="modal-cliente-input"
                                        type="text"
                                        id="telefone2_usuario"
                                        name="telefone2_usuario"
                                        value={dadosForm.telefone2_usuario}
                                        onChange={e => handleChange(e)}
                                    />
                                </div>

                            </div>
                            
                            <div className="modal-cadastrar-novo-usuario-bloco2">
                                <div className="previsualizacao-perfil">
                                    {previsualizacao ? (
                                        <img src={previsualizacao} alt="Imagem de perfil do usuário" />
                                    ) : (
                                        <img src={generic_user} alt="Imagem de perfil do usuário" />
                                    )}
                                </div>

                                <input type="file" onChange={e => handleImagem(e)}/>
                            </div>

                            <div className="modal-cadastrar-novo-usuario-base">
                                <div className="modal-editar-informacoes-comuns-wrapper-botoes">
                                    <button className="button-cancel" type="button" onClick={() => props.fecharModal()}> Cancelar </button>
                                    
                                    {props.doneSalvarNovoUsuario ? (
                                        <button className="button-submit" type="submit" onClick={(e) => salvarNovoCliente(e)}> Salvar </button>
                                    )  :  (
                                        <button className="button-carregando" >
                                            <DynamicLoading />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}