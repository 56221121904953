// Referências externas
import React from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';

const ItemAutomacaoEControle = ( props ) => {
  const unitario_compra = props.dados.preco_com_frete;
  const unitario_venda = unitario_compra * (props.estadoProposta.valor_global / props.valorProdutos);
  return (
    <tr key={props.dados.item}>
        {/* Quantidade */}
        <td>
          <div className="quantidade-automacao_e_controle">
            <button onClick={() => props.diminuirQuantidade()}><FiMinusCircle size={15}/></button>
            <div>
              {parseInt(props.dados.quantidade).toLocaleString('pt-BR', {style: 'decimal'})}
            </div>
            <button onClick={() => props.aumentarQuantidade()}><FiPlusCircle size={15}/></button>
          </div>
        </td>

        {/* Nome do Item */}
        <td>{props.dados.item}</td>

        {/* Preço Unit. Compra */}
        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(unitario_compra)}</td>
        
        {/* Preço Unit. Venda */}
        <td>
          {props.dados.quantidade > 0 ?
            (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(unitario_venda))
            :
            (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0))
          }
        </td>
        
        {/* Espaço */}
        <td style={{borderTop: "1px solid #fff", borderBottom: "1px solid #fff"}}></td>
        
        {/* Subtotal */}
        <td>
          {props.dados.quantidade > 0 ?
            (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.dados.quantidade * unitario_venda))
            :
            (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0))
          }
        </td>
    </tr>
  );
};

export default ItemAutomacaoEControle;