// Referências externas
import React, { useState, useEffect } from 'react';
import { Header } from '../../common';
import { useHistory } from 'react-router-dom';
import { Loading, DynamicLoading } from '../../common';
import { FiEdit, FiTrash2, FiUserPlus } from 'react-icons/fi';

// Referências internas
import api from '../../services/api';
import generic_user from '../../assets/users/generic_user.png';
import andre from '../../assets/users/andre.jpg';
import helton from '../../assets/users/helton.jpg';
import goncalves from '../../assets/users/goncalves.png';
import ana_sabino from '../../assets/users/ana_sabino.png';
import paulina from '../../assets/users/paulina.png';
import patricia from '../../assets/users/patricia.jpg';
import marcelo from '../../assets/users/marcelo.png';
import flavia from '../../assets/users/flavia.jpg';
import guilherme from '../../assets/users/guilherme.jpg';
import lessa from '../../assets/users/lessa.jpg';
import marcos from '../../assets/users/marcos.jpg';
import joao from '../../assets/users/joao.jpg';
import pedro from '../../assets/users/pedro.jpg';
import valmir from '../../assets/users/valmir.png';
import pedroh from '../../assets/users/pedroh.png';
import leonardo from '../../assets/users/leonardo.png';
import george from '../../assets/users/george.jpg';
import iago from '../../assets/users/iago.png';
import vanderlei from '../../assets/users/vanderlei.jpg';
import suy from '../../assets/users/suy.jpg';
import alex_vieira from '../../assets/users/alex_vieira.jpg';
import beatriz from '../../assets/users/beatriz.jpg';

// Estilo
import './styles.css';

export default function PainelControle() {
    const history = useHistory();

    const [done, setDone] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [dadosRoute, setDadosRoute] = useState();

    const [usuarioExibido, setUsuarioExibido] = useState({});
    const [profilePicture, setProfilePicture] = useState(generic_user);

    const imagem_usuarios = {
        andre: andre,
        helton: helton,
        goncalves: goncalves,
        ana_sabino: ana_sabino,
        paulina: paulina,
        patricia: patricia,
        marcelo: marcelo,
        flavia: flavia,
        guilherme: guilherme,
        lessa: lessa,
        marcos: marcos,
        joao: joao,
        pedro: pedro,
        valmir: valmir,
        pedroh: pedroh,
        leonardo: leonardo,
        george: george,
        iago: iago,
        vanderlei: vanderlei,
        alex_vieira: alex_vieira,
        beatriz: beatriz,
        suy: suy,
        generic_user: generic_user
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;
        
        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));

        if (tokenJSON.imagem_usuario.includes('https')) {
            setProfilePicture(tokenJSON.imagem_usuario);
        } else {
            setProfilePicture(imagem_usuarios[tokenJSON.imagem_usuario]);
        }

        api.get('dados').then((response) => {
            setDadosRoute(response.data);

            try {
                const arrayUsuarioBusca = response.data.responsaveis.filter(x => x.email_usuario === tokenJSON.email_usuario)
                setUsuarioExibido(arrayUsuarioBusca[0]);
            } catch (error) { }

            setDone(true);
        });

    }, []);

    return (
        <div className="container">
            <Header />

            <div className="body-container">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            Minha conta
                        </div>
                    </div>
                    <div className="type2-content">
                        {(!done) ? (
                        // {(done || dadosRoute === undefined) ? (
                                <div>
                                    <Loading />
                                </div>
                            )  :  (
                                <div className="user-wrapper">
                                    <div className="user-profile">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Nome</th>
                                                    <td colSpan="3">{usuarioExibido.nome_usuario}</td>
                                                </tr>
                                                <tr>
                                                    <th>E-mail</th>
                                                    <td colSpan="3">{usuarioExibido.email_usuario}</td>
                                                </tr>

                                                {usuarioExibido.telefone2_usuario !== "" ? (
                                                    <>
                                                        <tr>
                                                            <th>Telefone 1</th>
                                                            <td>{usuarioExibido.telefone1_usuario}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Telefone 2</th>
                                                            <td>{usuarioExibido.telefone2_usuario}</td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <th>Telefone</th>
                                                        <td>{usuarioExibido.telefone1_usuario}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>Comissão padrão</th>
                                                    <td>
                                                        {(usuarioExibido.comissao_usuario / 100).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="user-image">
                                        <img src={profilePicture} alt="Imagem de perfil do usuário" />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}