// Referências externas
import React, { useEffect, useState } from 'react';
import { FiAlertTriangle, FiSearch } from 'react-icons/fi';

// Referências internas
import { DynamicLoading } from '../../../common'
import validateClientInfo from './validateClientInfo';
import useClientForm from './useClientForm';
import '../styles.css';
import './styles.css';
import apiCNPJ from '../../../services/apiCNPJ';

// Referências internas
const permissaoUsuario = require('../../../services/permissaoUsuario');

const ClientForm = ( props ) => {
  const [token, setToken] = useState({});

  const [salvarClienteCarregando, setSalvarClienteCarregando] = useState(false);
  const [consultarCNPJCarregando, setConsultarCNPJCarregando] = useState(false);

  const {
    setValueObject,
    handleChange,
    handleChangeDocumento,
    handleChangeResponsavel,
    handleChangeNomeRazaoCliente,
    salvar,
    values,
    errors,
    setErrors} = useClientForm(
      validateClientInfo,
      props.history,
      props.notificarUsuarioExistente,
      props.notificarErro,
      props.notificarErroGenerico,
      setSalvarClienteCarregando,
      props.parentProps.fecharModalCliente,
      props.parentProps.customers,
      props.parentProps.setCustomers
  );

  async function consultaCNPJ() {
    let erros = validateClientInfo(values);
    setErrors(erros);

    if (errors.documento_cliente) {

      setConsultarCNPJCarregando(true);

      try {
          const response = await apiCNPJ.get('dados');

      } catch (error) {
        props.notificarErro();
      }

      setConsultarCNPJCarregando(false);
    }

  }

  function definirSubmit(e) {
    if (Object.keys(props.parentProps.estadoRecebido).length > 0) {
      salvar(e, 'salvarEGerarProposta', props.parentProps.estadoRecebido.origem);
    } else {
      salvar(e, 'salvarApenas', props.parentProps.estadoRecebido.origem);
    }
  }
  
  function verificar_cliente_existente() {
    if (Object.keys(props.clienteExistente).length > 0) {
      // console.log(props.clienteExistente)
      setValueObject({
        ID_cliente: props.clienteExistente.ID_cliente,
        titulo_cliente: props.clienteExistente.titulo_cliente,
        nome_razao_cliente: props.clienteExistente.nome_razao_cliente,
        documento_cliente: props.clienteExistente.documento_cliente,
        documento_cliente_mascara: props.clienteExistente.documento_cliente_mascara,
        id_usuario: props.clienteExistente.id_usuario
      });
    };
  }
  
  useEffect(() => {
    const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
    setToken(tokenJSON);

    // Quando a permissão 50 não é atendida o id é alterado de '' para 0. Assim a verificação permite
    // que as informações sejam enviadas para o banco onde o id_usuario correto será obtido.
    if (!permissaoUsuario.verificar(50, token.tipo_usuario)) {
      setValueObject({
        id_usuario: 0
      })
    }

    verificar_cliente_existente();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clienteExistente])


  return (
    <form onSubmit={e => { definirSubmit(e); }}>
      <div className="input-group">
          <label htmlFor="titulo_cliente" className="label-client-text">
            Título {errors.titulo_cliente && <FiAlertTriangle size={12} color="#ff0000"/>}
          </label>

          <div className="modal-cliente-radio-grupo">

            <div className="modal-cliente-radio">
              <input type="radio" name="titulo_cliente" checked={values.titulo_cliente === "Sr."} id="Radio1" value="Sr." onChange={e => { handleChange(e); }}/>
              <label htmlFor="Radio1" className="label-client-radio">
                Sr.
              </label>
            </div>

            <div className="modal-cliente-radio">
              <input type="radio" name="titulo_cliente" checked={values.titulo_cliente === "Sra."} id="Radio2" value="Sra." onChange={e => { handleChange(e); }}/>
              <label htmlFor="Radio2" className="label-client-radio">
                Sra.
              </label>
            </div>

            <div className="modal-cliente-radio">
              <input type="radio" name="titulo_cliente" checked={values.titulo_cliente === "N/A"} id="Radio3" value="N/A" onChange={e => { handleChange(e); }}/>
              <label htmlFor="Radio3" className="label-client-radio">
                N/A (Pessoa Jurídica)
              </label>
            </div>
          </div>

      </div>

      <div className="input-group">
          <label htmlFor="nome_razao_cliente" className="label-client-text">
            Nome Completo / <br/> Razão Social {errors.nome_razao_cliente && <FiAlertTriangle size={12} color="#ff0000"/>}
          </label>
          <input
              className="modal-cliente-input"
              type="text"
              id="nome_razao_cliente"
              name="nome_razao_cliente"
              value={values.nome_razao_cliente}
              onChange={e => { handleChange(e); }}
              // onChange={e => { handleChangeNomeRazaoCliente(e); }}
          />
      </div>

      <div className="input-group">
          <label htmlFor="documento_cliente" className="label-client-text">
            CPF / CNPJ {errors.documento_cliente && <FiAlertTriangle size={12} color="#ff0000"/>}
          </label>
          {/* {values.titulo_cliente === "N/A" ? (
            <>
              <input
                className="modal-cliente-input-narrow"
                type="text"
                id="documento_cliente"
                name="documento_cliente"
                value={values.documento_cliente_mascara}  
                onChange={e => { handleChangeDocumento(e); }}
                maxLength={18}
              />

              <button onClick={() => {}} className="search1">
                <FiSearch size={18} />
              </button>
            </>
          ) : ( */}
            <input
              className="modal-cliente-input"
              type="text"
              id="documento_cliente"
              name="documento_cliente"
              value={values.documento_cliente_mascara}  
              onChange={e => { handleChangeDocumento(e); }}
              maxLength={18}
            />
          {/*  )
           } */}
      </div>
      
      {permissaoUsuario.verificar(50, token.tipo_usuario) ? (
        <div className="input-group">
          <label htmlFor="id_usuario" className="label-client-text">
            Agente Comercial {errors.id_usuario && <FiAlertTriangle size={12} color="#ff0000"/>}
          </label>

            <select name="id_usuario" onChange={e => { handleChangeResponsavel(e); }} value={values.id_usuario} className="modal-cliente-select" >
              <option value="0">Selecione</option>
              {props.parentProps.rotaDados.responsaveis
                .map(responsavel => (
                    <option key={responsavel.id_usuario} value={responsavel.id_usuario}>{responsavel.nome_usuario}</option>
                ))
              }
            </select>          

        </div>
      ) : ( <></> )}

      <div className="dados-cliente-wrapper-botoes">
          <button className="button-cancel" type="button" onClick={() => props.parentProps.fecharModalCliente()}> Cancelar </button>
          
          {salvarClienteCarregando ? (
            <button className="modal-cliente-submit-carregando"> <DynamicLoading width='40px' /> </button>
          ):
          Object.keys(props.parentProps.estadoRecebido).length > 0 ? (
            <>
              <button className="button-submit" type="button" onClick={e => salvar(e, 'salvarApenas')}> Salvar </button>
              <button className="dados-cliente-botao-composto" type="submit"> Salvar e Gerar Proposta </button>
            </>
          ):(
            <>
              <button className="button-submit" type="submit"> Salvar </button>
            </>
          )}
      </div>

    </form>
  );
};

export default ClientForm;