// Referências externas
import React from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';

const ItemEstacaoVeicular = ( props ) => {
  const unitario_compra = props.dados.preco_com_frete;
  const unitario_venda = unitario_compra * (props.estadoProposta.valor_final / props.estadoProposta.parcial_produto);
  return (
    <tr key={props.dados.item}>
        {/* Quantidade */}
        <td>
          <div className="quantidade-estacao-veicular">
            <button onClick={() => props.diminuirQuantidade(props.dados)}><FiMinusCircle size={15}/></button>
            <div>
              {parseInt(props.dados.quantidade).toLocaleString('pt-BR', {style: 'decimal'})}
            </div>
            <button onClick={() => props.aumentarQuantidade(props.dados)}><FiPlusCircle size={15}/></button>
          </div>
        </td>

        {/* Nome do Item */}
        <td>{props.dados.item}</td>

        {/* Preço Unit. Compra */}
        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(unitario_compra)}</td>
        
        {/* Margem */}
        <td><input type="number" style={{textAlign: "center"}} value={props.estadoProposta.margem_proposta} disabled/></td>
        
        {/* Preço Unit. Venda */}
        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(unitario_venda)}</td>
        
        {/* Espaço */}
        <td style={{borderTop: "1px solid #fff", borderBottom: "1px solid #fff"}}></td>
        
        {/* Subtotal */}
        <td>
          {props.dados.quantidade > 0 ?
          (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.dados.quantidade * unitario_venda))
          :
          (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0))}
        </td>
    </tr>
  );
};

export default ItemEstacaoVeicular;