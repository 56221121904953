export default function validateClientInfo(values) {
  let errors = {};

  if (!values.numero_proposta.trim()) {
    errors.numero_proposta = 'values.numero_proposta obrigatório';
  }

  if (!values.revisao_proposta.trim()) {
    errors.revisao_proposta = 'values.revisao_proposta obrigatório';
  }

  
  return errors;
}




