// Referências externas
import React, { useState, useEffect } from 'react';
import { FiFilePlus, FiHelpCircle, FiCheck, FiRewind } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading } from '../../common';
import api from '../../services/api';
import ModalNovaProposta from './form/ModalNovaProposta';

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

export default function PropostasResumidas() {
    const history = useHistory();
    
    const [token, setToken] = useState({});

    const [tabelaVigente, setTabelaVigente] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [customerModal, setCustomerModal] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [done, setDone] = useState(true);
    const [deletar, setDoneDeletar] = useState(true);
    const [listaClientesCarregando, setListaClientesCarregando] = useState(true);

    const [buscaListaPropostas, setBuscaListaPropostas] = useState('');
    const [modalBuscaClienteAberto, setModalBuscaClienteAberto] = useState(false);

    async function obterPropostaCompleta(propostaSelecionadaResumida) {
        const numero_proposta = propostaSelecionadaResumida.numero_proposta;
        const revisao_proposta = propostaSelecionadaResumida.revisao_proposta;

        const response = await api.get(`propostas/${numero_proposta}/${revisao_proposta}`);
        const propostaCompleta = response.data;

        return propostaCompleta[0];
    }


    function vocativo(proposta) {
        if(proposta.titulo_cliente !== "N/A") {
            return proposta.titulo_cliente + " " + proposta.nome_razao_cliente;

        } else {
            return proposta.nome_razao_cliente;
        }
    }

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function openCustomerModal() {
        setListaClientesCarregando(true);
        api.get('clientes').then(response => {
            setCustomers(response.data);
            setListaClientesCarregando(false);
        })
        setCustomerModal(true);
    }

    function carregareA() {
        setListaClientesCarregando(true);
        api.get('clientes').then(response => {
            setCustomers(response.data);
            setListaClientesCarregando(false);
        })
        setCustomerModal(true);
    }

    function novaProposta(id) {
        history.push({
            pathname: `propostas/simular`,
            origin: 'propostas',
            data:  id
        })        
    }

    async function editarProposta(propostaSelecionadaResumida) {
        const propostaSelecionada = await obterPropostaCompleta(propostaSelecionadaResumida);
        
        const id = {
            ID_cliente: propostaSelecionada.ID_cliente,
            titulo_cliente: propostaSelecionada.titulo_cliente,
            nome_razao_cliente: propostaSelecionada.nome_razao_cliente
        }

        history.push({
            pathname: `propostas/simular`,
            origin: 'propostas',
            data:  id,
            proposal: propostaSelecionada
        })        
    }

    async function novaRevisao(propostaSelecionada) {
        setDone(false);

        const send = {
            "numero_proposta_duplicada": propostaSelecionada.numero_proposta,
            "revisao_proposta_duplicada": propostaSelecionada.revisao_proposta
        }
        const response = await api.post('propostas/duplicar', send);
        const propostaRevisada = response.data;

        const id = {
            ID_cliente: propostaRevisada.ID_cliente,
            titulo_cliente: propostaRevisada.titulo_cliente,
            nome_razao_cliente: propostaRevisada.nome_razao_cliente
        }

        history.push({
            pathname: `propostas/simular`,
            origin: 'propostas',
            data: id,
            proposal: propostaRevisada
        })

        setDone(true);       
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);
        
        async function solicitarPropostasEClientes() {
            setDone(false);
            try {
                const responseDados = await api.get('dados')
                setTabelaVigente(responseDados.data.tabela_vigente.chave);

                const response = await api.get('propostas')
                setProposals(response.data);
            } catch (error) {
                notificarErro();
            }
            setDone(true);

            
            setListaClientesCarregando(true);
            try {
                const response = await api.get('clientes')
                setCustomers(response.data);
            } catch (error) {
                notificarErro();
            }
            setListaClientesCarregando(false);
        }

        solicitarPropostasEClientes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            {permissaoUsuario.verificar(50, token.tipo_usuario) ? (
                                <div>Propostas resumidas</div>
                            ) : (
                                <div>Propostas</div>
                            )}
                        </div>

                        <div className="text-input-search-wraper">
                            <input
                                autoFocus
                                name="busca_lista_proposta"
                                className="text-input-search"
                                type="text"
                                id="busca_lista_proposta"
                                placeholder="Pesquisar"
                                onChange={e => { setBuscaListaPropostas(e.target.value); }}
                                autoComplete="off"
                            />
                            
                            <div className="icone-ajuda">
                                <FiHelpCircle size={18}/>
                            </div>

                            <div className="esconder-ajuda">
                                É possível buscar por número de proposta e nome do cliente
                            </div>
                        </div>

                        { modalBuscaClienteAberto && <ModalNovaProposta listaClientesCarregando={listaClientesCarregando} clientes={customers} fechar={() => setModalBuscaClienteAberto(false)} novaProposta={novaProposta} normalizar_texto={normalizar_texto} /> }

                        <button className="type1-main-button"  onClick={() => setModalBuscaClienteAberto(true)} >
                            <FiFilePlus/>
                            <span>Nova proposta</span>
                        </button>
                    </div>

                    <div className="type3-content">
                        {!done ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (
                            <table className="proposals-table">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Revisão</th>
                                        <th>Cliente</th>
                                        <th>Data</th>
                                        <th>Valor</th>
                                        <th>Sistema</th>
                                        <th>Agente Comercial</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {proposals
                                        .filter(proposal => {
                                            if (buscaListaPropostas.length > 0 && buscaListaPropostas.replace(new RegExp('[0-9]','gi'), '') === '') {
                                                const proposta_atual_normalizada = normalizar_texto(proposal.numero_proposta);
                                                const proposta_desejada_normalizada = normalizar_texto(buscaListaPropostas);

                                                if (proposta_atual_normalizada.includes(proposta_desejada_normalizada)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            } else {
                                                const cliente_atual_normalizado = normalizar_texto(proposal.nome_razao_cliente);
                                                const cliente_desejado_normalizado = normalizar_texto(buscaListaPropostas);

                                                if (cliente_atual_normalizado.includes(cliente_desejado_normalizado)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            }
                                        })
                                        .map(proposal => {
                                            let data_apresentacao_proposta = (new Date(proposal.data_apresentacao_proposta)).toLocaleDateString();

                                            return (
                                                <tr key={String(proposal.numero_proposta)+' '+String(proposal.revisao_proposta)}>

                                                    <td>{proposal.numero_proposta}</td>

                                                    <td className="icone-desatualizado">
                                                        <span> {proposal.revisao_proposta} </span>
                                                        {
                                                            (
                                                                tabelaVigente !== undefined
                                                                && proposal.tabela_considerada !== undefined
                                                                && proposal.tabela_considerada === tabelaVigente
                                                            ) ? (
                                                                <>
                                                                    <div className="icone-ajuda-desatualizado">
                                                                        <FiCheck color={'#2AAC2F'} size={18}/> 
                                                                    </div>
                                                                
                                                                    {/* <div className="esconder-ajuda-desatualizado">
                                                                        Proposta atualizada
                                                                    </div> */}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="icone-ajuda-desatualizado">
                                                                        <FiRewind color={'#E9190F'} size={18}/> 
                                                                    </div>
                                                                
                                                                    {/* <div className="esconder-ajuda-desatualizado">
                                                                        Proposta DESATUALIZADA
                                                                    </div> */}
                                                                </>
                                                            )}
                                                    </td>
                                                    
                                                    <td>{vocativo(proposal)}</td>
                                                    <td>{data_apresentacao_proposta}</td>
                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(proposal.valor_final)}</td>                                    
                                                    <td>{proposal.sistema_sugerido.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 4})} kWp</td>
                                                    <td>{proposal.nome_usuario}</td>

                                                    <td>
                                                        <div className="type4-functions">

                                                            <div><button className="view-button1" onClick={() => editarProposta(proposal)}>
                                                                Visualizar
                                                            </button></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>

                            </table>
                    
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
}