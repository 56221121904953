// Referências externas
import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { Loading } from '../../../../common';

export default function RetornoFinanceiro (props) {
    return (
        <>
            {(props.done && props.resumoAnaliseInvalido) && <div className="resumo-invalido"> <FiAlertTriangle size={18} />  <h3> RETORNO INVÁLIDO </h3> <FiAlertTriangle size={18} />  </div>}
            {!props.done ? (
                <div>
                    <Loading />
                </div>
            )  :  ( 
                <>
                    <table className="table-results3">
                        <tbody>
                            <tr>
                                <th>LCOE</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits: 4}).format(props.analise.lcoe)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results3">
                        <thead>
                            <tr>
                                <th colSpan="2">VPL</th>
                            </tr>
                        </thead>

                        <tbody>
                            {props.analise.vpl_escalonado.map(vpl => {
                                return(
                                    <tr key={vpl.nome}>
                                        <td>{vpl.nome}</td>
                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(vpl.valor)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <table className="table-results3">
                        <thead>
                            <tr>
                                <th colSpan="2">TIR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.analise.tir_escalonado.map(tir => {
                                return(
                                    <tr key={tir.nome}>
                                        <td>{tir.nome}</td>
                                        <td>{Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(tir.valor / 100)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <table className="table-results3">
                        <thead>
                            <tr>
                                <th colSpan="2">Economia</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.analise.economia_escalonado.map(economia => {
                                return(
                                    <tr key={economia.nome}>
                                        <td>{economia.nome}</td>
                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(economia.valor)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            )}
            {(props.done && props.resumoAnaliseInvalido) && <div className="resumo-invalido"> <FiAlertTriangle size={18} />  <h3> RETORNO INVÁLIDO </h3> <FiAlertTriangle size={18} />  </div>}
        </>
    )
}