// Referências externas
import React from 'react';

// Referências internas
import { Loading } from '../../../../common';

export default function Resumo (props) {
    return (
        <>
            {!props.done ? (
                <div>
                    <Loading />
                </div>
                    )  :  ( 
                <>
                    <table className="table-results2">
                        <tbody>
                            <tr>
                                <th>Total produtos</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_produtos)}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results1">
                        <tbody>
                            <tr>
                                <th>Comissão</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_comissao)}</td>
                            </tr>
                            <tr>
                                <th>Outros valores</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.outros_valores)}</td>
                            </tr>
                            <tr>
                                <th>Imposto</th>
                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_imposto)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table-results2">
                        <tbody>
                            <tr className="destaque_ganhos">
                                <th>Margem</th>
                                <td>
                                    {Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_margem)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className="table-results2">
                        <tbody>
                            <tr className="valor_global">
                                <th>Valor Global</th>
                                <th>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.resumo.valor_global)}</th>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                </>
            )}
        </>
    )
}