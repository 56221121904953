export default function validarEstado(email_usuario, password) {
    let erros = {};

    if (!email_usuario.trim() || email_usuario.length < 5) {
        erros.email_usuario = 'Campo obrigatório';
        erros.geral = 'Valor inválido';
    }

    if (!password.trim() || password.length < 5) {
        erros.password = 'Campo obrigatório';
        erros.geral = 'Valor inválido';
    }

    return erros;
}