// Referências internas
const permissaoUsuario = require('./permissaoUsuario');

function checkAuth() {
    // const currentDate = new Date();

    const token = JSON.parse(localStorage.getItem('sgc_br'));

    if (!token) {
        return false;
    }

    // Linhas abaixo silenciadas em 26/10/2021
    // try {
    //     if (token.expiration < currentDate.getTime()) {
    //         localStorage.removeItem('sgc_br');
    //         return false;
    //     }
    // } catch (error) {
    //     localStorage.removeItem('sgc_br');
    //     return false;
    // }

    // token.expiration = currentDate + (1000 * 60 * process.env.SESSION_MINUTES);


    // Linhas abaixo silenciadas em 26/10/2021
    // token.expiration = (currentDate.getTime() + (1000 * 60 * 30));
    // localStorage.setItem('sgc_br', JSON.stringify(token))
    return true;
}

function checkAuthInternal() {
    const token = JSON.parse(localStorage.getItem('sgc_br'));

    if (!token) {
        return false;
    }

    if (permissaoUsuario.verificar(10, token.tipo_usuario)) {
        return true;
    } else {
        return false;
    }
}

function checkAdmin() {
    // const currentDate = new Date();

    const token = JSON.parse(localStorage.getItem('sgc_br'));

    if (!token) {
        return false;
    }

    // Linhas abaixo silenciadas em 26/10/2021
    // try {
    //     if (token.expiration < currentDate.getTime()) {
    //         localStorage.removeItem('sgc_br');
    //         return false;
    //     }
    // } catch (error) {
    //     localStorage.removeItem('sgc_br');
    //     return false;
    // }

    
    // token.expiration = currentDate + (1000 * 60 * process.env.SESSION_MINUTES);



    // Linhas abaixo silenciadas em 26/10/2021
    // token.expiration = (currentDate.getTime() + (1000 * 60 * 30));
    // localStorage.setItem('sgc_br', JSON.stringify(token))

    if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
        return true;
    } else {
        return false;
    }
}

function checkAuthNivel(nivelPermissaoUsuario) {
    const token = JSON.parse(localStorage.getItem('sgc_br'));

    if (!token) {
        return false;
    }

    if (permissaoUsuario.verificar(nivelPermissaoUsuario, token.tipo_usuario)) {
        return true;
    } else {
        return false;
    }
}

export { checkAuth, checkAuthInternal, checkAdmin, checkAuthNivel };