// Referências externas
import { useState, useEffect } from 'react';

// Referências internas
const useModalSalvarComoForm = (
    validateModalSalvarComo,
    salvarProposta,
    setModalSalvarComoAberto
  ) => {
  const [values, setValues] = useState({
    numero_proposta: '',
    revisao_proposta: ''
  });

  const [errors, setErrors] = useState({});

  function handleChange(e) {
    const { name, value } = e.target;
    const novoValor = {
      ...values,
      [name]: value
    }

    setValues(novoValor);
  };

  async function salvar(e) {
    e.preventDefault();
    let erros = validateModalSalvarComo(values);
    setErrors(erros);
    console.log(errors)

    if (Object.keys(erros).length === 0) {
      let bool_sucesso = await salvarProposta(values.numero_proposta, values.revisao_proposta);
      bool_sucesso && setModalSalvarComoAberto(false);
    }
  }

  return {
    handleChange,
    salvar,
    values,
    errors
  };
};

export default useModalSalvarComoForm;