import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

export default function ModalUnitario(props) {
    return (
        <div className="modal-fundo">
            <div className="modal2">
                <div className="modal-titulo">
                    <FiAlertCircle size={88} />
                    <p>O CONSUMO SERÁ PERDIDO!</p>
                    {/* <p>O CONSUMO SERÁ SOBRESCRITO!</p> */}
                </div>
                <div className="modal-conteudo">
                    <p>
                        Após a confirmação os valores inseridos para consumo médio e mês a mês serão perdidos!
                    </p>
                    <br/>
                    <br/>
                    <p>
                        Deseja proseguir com essa ação?
                    </p>
                    <br/>
                </div>
                <div className="modal-acao">
                    <button className="modal-botao" onClick={(e) => props.setModalUnitarioAberto(false)}>
                        Cancelar
                    </button>
                    <button className="modal-botao" onClick={(e) => {props.apagar_consumo(); props.setModalUnitarioAberto(false);}}>
                        Proseguir
                    </button>
                </div>
            </div>
        </div>
    );
}