// Referências externas
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Referências internas
import { Loading } from '../../../../common'

const FormSignup = () => {
  return (
        <form className="form1" noValidate>
          <Tabs>
            <TabList>
              <Tab>Geral</Tab>
            </TabList>

            <div className="proposal-subelement">

              <TabPanel>
                <Loading />
              </TabPanel>
              
            </div>
          
        </Tabs>
      </form>
  );
};

export default FormSignup;