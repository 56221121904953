// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEdit, FiTrash2, FiUserPlus, FiHelpCircle } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading, DynamicLoading } from '../../common';
import api from '../../services/api';
import { Mascara } from './Formulario/MascaraDocumento';
import ModalConfirmarExclusao from './Formulario/ModalConfirmarExclusao';
import ModalCliente from './Formulario/ModalCliente';

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

export default function Start(location) {
    const history = useHistory();

    const [token, setToken] = useState({});

    const [rotaDados, setRotaDados] = useState(false);
    const [rotaDadosCarregando, setRotaDadosCarregando] = useState(false);

    const [classeFuncoes, setClasseFuncoes] = useState('');

    const objetoClienteVazio = {
        ID_cliente: '',
        titulo_cliente: '',
        nome_razao_cliente: '',
        documento_cliente: '',
        documento_cliente_mascara: '',
        id_usuario: ''
    }

    const [estadoRecebido, setEstadoRecebido] = useState({});
    const [clienteExibido, setClienteExibido] = useState(objetoClienteVazio);
    const [modalClienteAberto, setModalClienteAberto] = useState(false);
    const [modalConfirmarExclusaoAberto, setModalConfirmarExclusaoAberto] = useState(false);
    const [clienteIntencaoExcluir, setClienteIntencaoExcluir] = useState('');

    const [customers, setCustomers] = useState([]);

    const [clienteDesejado, setClienteDesejado] = useState('');

    const [done, setDone] = useState(true);
    const [doneDiv1, setDoneDiv1] = useState(true);

    async function carregarDadosListaResponsavel() {
        setRotaDadosCarregando(true);

        try {
            const response = await api.get('dados');
            setRotaDados(response.data);

        } catch (error) {
            notificarErroPersonalizado(error);
        }

        setRotaDadosCarregando(false);
    }

    function fecharModalCliente() {
        setModalClienteAberto(false);
        setClienteExibido(objetoClienteVazio);

        if (location.state) {
            history.goBack()
        }
    }

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function handleDeleteCustomer(id) {
        setDoneDiv1(false);
        try {
            await api.delete(`clientes/${id}/1`);

            setCustomers(customers.filter(customer => customer.ID_cliente !== id));

        } catch (error) {
            notificarErroPersonalizado(error);
        }
        setDoneDiv1(true);
    }

    async function handleNewProposal(id) {
        if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
            history.push({
                pathname: `propostas_completas/simular`,
                data:  id,
                origin: '/clientes'
            })
        } else {
            history.push({
                pathname: `propostas/simular`,
                data:  id,
                origin: '/clientes'
            })
        }
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);

        if (location.location.state) {
            setEstadoRecebido(location.location.state);
            setModalClienteAberto(true);
            
            // Redundante por já ser tratado no fechamento do modal e na inicialização do state clienteExibido.
            setClienteExibido(objetoClienteVazio);
        }

        if (permissaoUsuario.verificar(50, tokenJSON.tipo_usuario)) {
            setClasseFuncoes('type1-functions');

            carregarDadosListaResponsavel();
        } else {
            setClasseFuncoes('type2-functions');
        }


        async function solicitarClientes() {
            setDone(false);
            try {
                const response = await api.get('clientes');

                setCustomers(response.data);
                
            } catch (error) {
                notificarErroPersonalizado(error);
            }

            setDone(true);
        }

        solicitarClientes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />
            { modalConfirmarExclusaoAberto && <ModalConfirmarExclusao excluir_cliente={handleDeleteCustomer} clienteIntencaoExcluir={clienteIntencaoExcluir} setClienteIntencaoExcluir={setClienteIntencaoExcluir} setModalConfirmarExclusaoAberto={setModalConfirmarExclusaoAberto} /> }
            { modalClienteAberto && <ModalCliente customers={customers} setCustomers={setCustomers} rotaDados={rotaDados} rotaDadosCarregando={rotaDadosCarregando} clienteExibido={clienteExibido} fecharModalCliente={fecharModalCliente} estadoRecebido={estadoRecebido} /> }

            <div className="body-container">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            Clientes cadastrados
                        </div>

                        <div className="text-input-search-wraper">
                            <input
                                autoFocus
                                name="nome_razao_cliente"
                                className="text-input-search"
                                type="text"
                                id="nome_razao_cliente"
                                placeholder="Pesquisar"
                                onChange={e => { setClienteDesejado(e.target.value); }}
                                autoComplete="off"
                            />
                            
                            <div className="icone-ajuda">
                                <FiHelpCircle size={18}/>
                            </div>

                            <div className="esconder-ajuda">
                                É possível buscar por nome e documento (CNPJ/CPF)
                            </div>
                        </div>



                        <button className="type1-main-button" onClick={() => setModalClienteAberto(true)}>
                            <FiUserPlus/>
                            <span>Cadastrar cliente</span>
                        </button>
                    </div>
                    <div className="type1-content">

                    {!done ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (


                        <table className="tabela-clientes">
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Assinatura</th>
                                    <th>Documento</th>
                                    <th>Agente Comercial</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {customers
                                    .filter(customer => {
                                        if (clienteDesejado.length > 0 && clienteDesejado.replace(new RegExp('[0-9]','gi'), '') === '') {

                                            if (customer.documento_cliente.includes(clienteDesejado)) {
                                                return customer.nome_razao_cliente;
                                            }
                                        } else {
                                            const cliente_atual_normalizado = normalizar_texto(customer.nome_razao_cliente);
                                            const clienteDesejado_normalizado = normalizar_texto(clienteDesejado);
    
                                            if (cliente_atual_normalizado.includes(clienteDesejado_normalizado)) {
                                                return customer.nome_razao_cliente;
                                            }
                                        }
                                    })
                                    .map(customer => {
                                        return(
                                            <tr key={customer.ID_cliente}>
                                                <td>{customer.titulo_cliente}</td>
                                                <td>{customer.nome_razao_cliente}</td>
                                                <td>{Mascara(customer.documento_cliente)}</td>
                                                <td>{customer.nome_usuario}</td>
                                                <td>
                                                    <div className={classeFuncoes}>

                                                        <button onClick={() => {setClienteExibido(customer); setModalClienteAberto(true);}} className="edit1">
                                                            <FiEdit size={18} />
                                                        </button>

                                                        {!permissaoUsuario.verificar(50, token.tipo_usuario) ? (
                                                            <></>
                                                        ) : !doneDiv1 ? (
                                                            <button className="white-background">
                                                                <DynamicLoading />
                                                            </button>
                                                        )  :  (
                                                            <button className="delete1" onClick={() => {setClienteIntencaoExcluir(customer.ID_cliente); setModalConfirmarExclusaoAberto(true);}} >
                                                                <FiTrash2 size={18} />
                                                            </button>
                                                        )}

                                                        <button onClick={() => handleNewProposal(customer)} className="new-proposal1">
                                                            Nova proposta Solar
                                                        </button>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            
                        </table>
                         ) }
                    </div>
                </div>
            </div>
        </div>
    );
}