import React, { Component } from 'react';
import Lottie from 'react-lottie';
import * as loading from '../../assets/7774-loading.json';
import './styles.css';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

// {...this.props.height ? (style={width: '40px'}) : style={width: '40px'}}

class DynamicLoading extends Component {

    render() {
        return (
            <div className="dynamic-loading-wrapper"  >
                <div className="dynamic-loading"  >
                    <Lottie style={{width: this.props.width, height: this.props.height}} options={defaultOptions} />
                    
                </div>
            </div>
        );

    }
}

export default DynamicLoading;
