// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiArrowLeft } from 'react-icons/fi';

// Referências internas
import { Header, Loading, DynamicLoading } from '../../../common';
import api from '../../../services/api';

// Estilo
import './styles.css';

export const CompararTabela = props => {
    const history = useHistory();

    const [doneCompararListas, setDoneCompararListas] = useState(true);
    const [doneSolicitarTabelasDisponiveis, setDoneSolicitarTabelasDisponiveis] = useState(false);
    const [tabelasDisponiveis, setTabelasDisponiveis] = useState([]);
    const [tabela1, setTabela1] = useState("");
    const [tabela2, setTabela2] = useState("");
    const [itensTabela1, setItensTabela1] = useState();
    const [itensTabela2, setItensTabela2] = useState();
    const [botaoCompararAtivado, setBotaoCompararAtivado] = useState();
    const [tableHeader, setTableHeader] = useState(["item", "tipo", "preco"]);
    const [exibicaoTabela1, setExibicaoTabela1] = useState([]);
    const [exibicaoTabela2, setExibicaoTabela2] = useState([]);
    const [tituloTabela1, setTituloTabela1] = useState("Tabela 1");
    const [tituloTabela2, setTituloTabela2] = useState("Tabela 2");

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function controleBotaoComparar(tabelas) {
        (tabelas[0] !== "" && tabelas[1] !== "") && setBotaoCompararAtivado(true);
    }

    function alterarTabela1(value) {
        console.log(1)
        if (value !== "" && value !== tabela2) {
            setTabela1(value);
            console.log([value, tabela2])
            controleBotaoComparar([value, tabela2]);
        }
    }

    function alterarTabela2(value) {
        console.log(2)
        if (value !== "" && value !== tabela1) {
            setTabela2(value);
            console.log([tabela1, value])
            controleBotaoComparar([tabela1, value]);
        }
    }

    async function compararListas() {
        setDoneCompararListas(false);
        try {
            const array_tabela_selecionada = {"array_tabela_selecionada": [tabela1, tabela2]};
            const response = await api.post(`painel/obter_tabela_array`, array_tabela_selecionada);

            let itensTabela1 = response.data.tabela_selecionada1.map(x => x.item);
            let itensTabela2 = response.data.tabela_selecionada2.map(x => x.item);

            let itensResultante = [...new Set([...itensTabela1, ...itensTabela2])];

            let objectItensTabela1 = Object.assign(
                {},
                ...response.data.tabela_selecionada1.map(
                    x => ({ [x.item]: x })
                )
            );

            let objectItensTabela2 = Object.assign(
                {},
                ...response.data.tabela_selecionada2.map(
                    x => ({ [x.item]: x })
                )
            );

            let comparacaoTabela1 = itensResultante.map(x => {
                if (objectItensTabela1.hasOwnProperty(x)) {
                    return objectItensTabela1[x];
                } else {
                    return {
                        "campo1": "",
                        "campo2": "",
                        "disjuntor": "",
                        "entrada": "",
                        "fabricante": "",
                        "garantia1": "",
                        "garantia2": "",
                        "campo_adicional1": "",
                        "id_item": "",
                        "item": x,
                        "monitoramento": "",
                        "parametro_unidade": "",
                        "parametro_valor": "",
                        "preco": 0,
                        "tipo": "",
                        "transformador": ""
                    }
                }
            });

            setTituloTabela1(tabela1)
            setExibicaoTabela1(comparacaoTabela1);

            let comparacaoTabela2 = itensResultante.map(x => {
                if (objectItensTabela2.hasOwnProperty(x)) {
                    return objectItensTabela2[x];
                } else {
                    return {
                        "campo1": "",
                        "campo2": "",
                        "disjuntor": "",
                        "entrada": "",
                        "fabricante": "",
                        "garantia1": "",
                        "garantia2": "",
                        "campo_adicional1": "",
                        "id_item": "",
                        "item": x,
                        "monitoramento": "",
                        "parametro_unidade": "",
                        "parametro_valor": "",
                        "preco": 0,
                        "tipo": "",
                        "transformador": ""
                    }
                }
            });

            setTituloTabela2(tabela2)
            setExibicaoTabela2(comparacaoTabela2);

            setDoneCompararListas(true);

        } catch (error) {
            console.log(error);
            setDoneCompararListas(true);
            notificarErro();
        }
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        async function solicitarTabelasDisponiveis() {
            // setDoneSolicitarTabelasDisponiveis(false);

            try {
                if (props.location.tabelasDisponiveis !== undefined && props.location.tabelasDisponiveis.length > 0) {
                    setTabelasDisponiveis(props.location.tabelasDisponiveis);
                } else {
                    const response = (await api.get(`painel/obter_tabelas_disponiveis`));
                    setTabelasDisponiveis(response.data.tabelas_disponiveis);
                }

                setDoneSolicitarTabelasDisponiveis(true);

            } catch (error) {
                notificarErro();
                setDoneSolicitarTabelasDisponiveis(true);
                console.log('solicitarTabelasDisponiveis()');
            }

        }
        solicitarTabelasDisponiveis();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="visualizar-tabela-title">
                            <div> Comparar Listas </div>
                        </div>

                        <button className="type1-main-button" onClick={() => history.push('/painel_controle')} >
                            <FiArrowLeft/>
                            <span>Voltar</span>
                        </button>
                    </div>
                    <div className="tabela-generica-content-2">
                        {!doneSolicitarTabelasDisponiveis ? (
                                <div>
                                    <Loading />
                                </div>
                            )  :  (
                                <>
                                    <div className="selecao-comparativo">
                                        <div>
                                            <label htmlFor="tabela1" className="label-client-text">Tabela 1</label>
                                            <select
                                                name="tabela1"
                                                onChange={e => { alterarTabela1(e.target.value) }}
                                                value={tabela1}
                                                className="painel-controle-select"
                                            >
                                                <option value=""> Selecione </option>
                                                {tabelasDisponiveis.map(tabela => (
                                                    tabela.chave === tabela1 ? (
                                                        <option style={{background: "#2aac2f", color:'#fff'}} key={tabela.chave} value={tabela.chave}>{tabela.valor.valor}</option>
                                                    ) : tabela.chave === tabela2 ? (
                                                        <option style={{background: "#b0b0b0", color:'#fff'}} key={tabela.chave} value={tabela.chave}>{tabela.valor.valor}</option>
                                                    ) : (
                                                        <option key={tabela.chave} value={tabela.chave}>{tabela.valor.valor}</option>
                                                    )
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label htmlFor="tabela2" className="label-client-text">Tabela 2</label>
                                            <select
                                                name="tabela2"
                                                onChange={e => { alterarTabela2(e.target.value) }}
                                                value={tabela2}
                                                className="painel-controle-select"
                                            >
                                                <option value=""> Selecione </option>
                                                {tabelasDisponiveis.map(tabela => (
                                                    tabela.chave === tabela1 ? (
                                                        <option style={{background: "#b0b0b0", color:'#fff'}} key={tabela.chave} value={tabela.chave}>{tabela.valor.valor}</option>
                                                    ) : tabela.chave === tabela2 ? (
                                                        <option style={{background: "#2aac2f", color:'#fff'}} key={tabela.chave} value={tabela.chave}>{tabela.valor.valor}</option>
                                                    ) : (
                                                        <option key={tabela.chave} value={tabela.chave}>{tabela.valor.valor}</option>
                                                    )
                                                ))}
                                            </select>
                                        </div>
                                    
                                        {!doneCompararListas ? (
                                            <button className="modal-cliente-submit-carregando"> <DynamicLoading width='40px' style={{marginTop: "5px"}} /> </button>
                                        ) : botaoCompararAtivado === true ? (
                                            <div className="wrapper-botao-comparar-tabela">
                                                <button className="button-submit-azul-ajustavel" onClick={() => { compararListas() }}> Comparar listas </button>
                                            </div>
                                        ) : (
                                            <div className="wrapper-botao-alterar-lista-vigente">
                                                <button className="button-submit-cinza-ajustavel" disabled> Comparar listas </button>
                                            </div>
                                        )}
                                    </div>
                                
                                    <br />
                                    <br />
                                    <div className="wrapper-comparativo">
                                        <div className="wrapper-table-comparar">
                                            <h3>{tituloTabela1}</h3>
                                            <br />
                                            <table className="table-pre-visualizacao">
                                                <thead>
                                                    <tr>
                                                        <th> item </th>
                                                        <th> tipo </th>
                                                        <th> preco </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(exibicaoTabela1.length > 0 && exibicaoTabela1) ? (
                                                        exibicaoTabela1.map((tableBodyRow) => (
                                                            <tr key={"tabela1" + tableBodyRow.item}>
                                                                <td> {tableBodyRow.item} </td>
                                                                <td> {tableBodyRow.tipo} </td>
                                                                <td> {(tableBodyRow.preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }))} </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                {tableHeader.map(() => (
                                                                    <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {tableHeader.map(() => (
                                                                    <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                                ))}
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="wrapper-table-comparar">
                                            <h3>{tituloTabela2}</h3>
                                            <br />
                                            <table className="table-pre-visualizacao">
                                                <thead>
                                                    <tr>
                                                        <th> item </th>
                                                        <th> tipo </th>
                                                        <th> preco </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(exibicaoTabela2.length > 0 && exibicaoTabela2) ? (
                                                        exibicaoTabela2.map((tableBodyRow) => (
                                                            <tr key={"tabela2" + tableBodyRow.item}>
                                                                <td> {tableBodyRow.item} </td>
                                                                <td> {tableBodyRow.tipo} </td>
                                                                <td> {(tableBodyRow.preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }))} </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="wrapper-diferença">
                                            <h3>Diferença</h3>
                                            <br />
                                            <table className="table-pre-visualizacao">
                                                <thead>
                                                    <tr>
                                                        <th> diferença </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(exibicaoTabela1.length > 0 && exibicaoTabela1 && exibicaoTabela2.length > 0 && exibicaoTabela2) ? (
                                                        exibicaoTabela1.map((x, index) => {
                                                            let valor1 = exibicaoTabela1[index].preco;
                                                            let valor2 = exibicaoTabela2[index].preco;
                                                            let diferenca = (valor2 / valor1) - 1;
                                                            if (diferenca > -0.00001 && diferenca < 0.00001) { diferenca = 0; }
                                                        
                                                            return (
                                                                <tr key={"diferenca" + x.item}>
                                                                    {(valor1 === 0 || valor2 === 0) ? (
                                                                        <td> 0 % </td>
                                                                    ) : diferenca > 0 ? (
                                                                        <td className='aumentoPrecoComparativo' >
                                                                            +{diferenca.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 3 })}
                                                                        </td>
                                                                    ) : diferenca < 0 ? (
                                                                        <td className='reducaoPrecoComparativo' >
                                                                            {diferenca.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 3 })}
                                                                        </td>
                                                                    ) : (
                                                                        <td>
                                                                            {diferenca.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 3 })}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                );
                                                        }
                                                        )
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="importar-tabela-vazia texto-center"> ..... </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        
    );
}