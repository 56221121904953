function verificar_cpf(cpf) {
  let verificacao_digito1 = 0;
  let verificacao_digito2 = 0;

  for (let i = 0; i <= 8; i++) {
    verificacao_digito1 += (10 - i) * parseInt(cpf[i]);
  }

  for (let i = 0; i <= 9; i++) {
    verificacao_digito2 += (11 - i) * parseInt(cpf[i]);
  }

  verificacao_digito1 = (verificacao_digito1 * 10) % 11;
  verificacao_digito2 = (verificacao_digito2 * 10) % 11;

  verificacao_digito1 === 10 && (verificacao_digito1 = 0);
  verificacao_digito2 === 10 && (verificacao_digito2 = 0);

  let cpf_reduzido = [...new Set(cpf)];

  // console.log(cpf_reduzido.length);

  if (parseInt(verificacao_digito1) === parseInt(cpf[9]) && parseInt(verificacao_digito2) === parseInt(cpf[10]) && cpf_reduzido.length >= 3) {
    return true;
  } else {
    return false;
  }
}

export default function validateClientInfo(values) {
  let errors = {};

  if (!values.titulo_cliente.trim()) {
    errors.titulo_cliente = 'titulo_cliente obrigatório';
  }

  if (!values.nome_razao_cliente.trim()) {
    errors.nome_razao_cliente = 'values.nome_razao_cliente obrigatório';
  } else if (values.nome_razao_cliente.length < 4) {
    errors.nome_razao_cliente = 'values.nome_razao_cliente.length inválido';
  }

  if (values.documento_cliente === "") {
    errors.documento_cliente = 'values.documento_cliente obrigatório';

  } else if (values.titulo_cliente === "N/A" && values.documento_cliente.length !== 14) {
    errors.documento_cliente = 'values.documento_cliente (CNPJ) inválido';

  } else if ((values.titulo_cliente !== "N/A" && values.documento_cliente.length !== 11) || (values.titulo_cliente !== "N/A" && verificar_cpf(values.documento_cliente) === false)) {
    errors.documento_cliente = 'values.documento_cliente (CPF) inválido';
  }

  if (!String(values.id_usuario).trim()) {
    errors.id_usuario = 'values.id_usuario obrigatório';
  }
  
  return errors;
}




