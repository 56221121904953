// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiArrowLeft } from 'react-icons/fi';

// Referências internas
import { Header, Loading, DynamicLoading } from '../../../common';
import api from '../../../services/api';

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../../services/permissaoUsuario');

export const VisualizarTabela = props => {
    const history = useHistory();

    const [tabelaExibida, setTabelaExibida] = useState([{tabela_vazia:""}]);
    const [tabelaSelecionada, setTabelaSelecionada] = useState();

    const [tituloTabela, setTituloTabela] = useState("");
    const [done, setDone] = useState(false);
    const [token, setToken] = useState({});

    const [buscaListaUsuarios, setBuscaListaUsuarios] = useState('');

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;
        setToken(JSON.parse(localStorage.getItem('sgc_br')));
        
        try {
            // props.location.dadosTabela:
            // {exibicao: , nome_mysql: , tabela: }
            
            setTituloTabela(props.location.dadosTabela.nome_exibicao);

            async function solicitarTabela() {
                setDone(false);
                try {
                    const response = await api.get(`painel/obter_tabela/${props.location.dadosTabela.nome_mysql}`);

                    let tabela_selecionada = response.data.tabela_selecionada;
                    setTabelaSelecionada(tabela_selecionada);

                    setTabelaExibida(tabela_selecionada.length > 0 ? tabela_selecionada : [['Tabela vazia']]);

                } catch (error) {
                    console.log(error);
                    setDone(true);
                    notificarErro();
                }

                setDone(true);

            }

            solicitarTabela();

        } catch (error) {
            console.log(error);
            console.log('props.location.tabela.chave');

            history.push({
                pathname: `painel_controle`,
                mensagem:  'Erro ao obter chave da tabela',
                origin: '/visualizar_tabela'
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="visualizar-tabela-title">
                            <div>{tituloTabela}</div>
                        </div>

                        <input
                            autoFocus
                            name="busca_lista_proposta"
                            className="text-input-search"
                            type="text"
                            id="busca_lista_proposta"
                            placeholder="Pesquisar"
                            onChange={e => { setBuscaListaUsuarios(e.target.value); }}
                            autoComplete="off"
                        />

                        <button className="type1-main-button" onClick={() => history.push('/painel_controle')} >
                            <FiArrowLeft/>
                            <span>Voltar</span>
                        </button>
                    </div>
                    <div className="tabela-generica-content">
                        {!done ? (
                                <div>
                                    <Loading />
                                </div>
                            )  :  (
                                <table className="table-pre-visualizacao">
                                    <thead>
                                        <tr>
                                            {Object.keys(tabelaExibida[0]).map(titulo => {
                                                    return (
                                                        <th key={titulo} className="texto-center">
                                                            {tabelaSelecionada.length > 0 ? titulo : tituloTabela}
                                                        </th>
                                                    )
                                                }
                                            )}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tabelaExibida.map((linha, indiceLinha) => {
                                                return (
                                                    <tr key={indiceLinha}>
                                                        {Object.values(linha).map((item, indiceItem) => {
                                                                return (
                                                                    <td key={indiceItem}>
                                                                        {(item === 0 || item === '0') ? ('') : (item)}
                                                                    </td>
                                                                )
                                                            }
                                                        )}
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        
    );
}