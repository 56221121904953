import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './pages/Login';

import Start from './pages/Start';
import Customers from './pages/Customers';
import Sistemas from './pages/Sistemas';
import DetalhesSistema from './pages/DetalhesSistema';

import Proposals from './pages/Proposals';
import ProposalSimulation from './pages/ProposalSimulation';
import PropostasResumidas from './pages/PropostasResumidas';
import PropostasResumidasSimulacao from './pages/PropostasResumidasSimulacao';

import PropostasBranco from './pages/PropostasBranco';

import PropostasEV from './pages/PropostasEV';
import PropostasEVSimulacao from './pages/PropostaEVSimulacao';

import PropostasGR from './pages/PropostasGR';
import PropostasGRSimulacao from './pages/PropostaGRSimulacao';

import PropostasOM from './pages/PropostasOM';
import PropostasOMSimulacao from './pages/PropostaOMSimulacao';

import PropostasBAT from './pages/PropostasBAT';
import PropostasBATSimulacao from './pages/PropostaBATSimulacao';

import PropostasNB from './pages/PropostasNB';
import PropostasNBSimulacao from './pages/PropostaNBSimulacao';

import PropostasAC from './pages/PropostasAC';
import PropostasACSimulacao from './pages/PropostaACSimulacao';

import PainelControle from './pages/PainelControle';
import {ImportarTabela} from './pages/ImportarTabela';
import {VisualizarTabela} from './pages/PainelControle/VisualizarTabela';
import {CompararTabela} from './pages/PainelControle/CompararTabela';

import Profile from './pages/Profile';
import Teste from './pages/Teste';

// import Session from './pages/Session';
// import Datalogger from './pages/Datalogger';
import PrintACTeste from './common/ProposalPrintAC';
// import ListaUsuarios from './pages/ListaUsuarios';

const authentication = require('./services/authentication');

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    authentication.checkAuth() ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/' }} />
      )
  )} />
)

const AuthRouteInternal = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    authentication.checkAuthNivel(10) ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/' }} />
      )
  )} />
)

const AuthRouteSuporte2 = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    authentication.checkAuthNivel(47) ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/' }} />
      )
  )} />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    authentication.checkAuthNivel(50) ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/' }} />
      )
  )} />
)

export default function Routes() {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />

        <AuthRouteInternal exact path="/inicio" component={Start} />
        <AuthRouteInternal exact path="/clientes" component={Customers} />
        <AuthRoute exact path="/sistemas" component={Sistemas} />
        <AuthRoute exact path="/sistemas/detalhes_sistemas" component={DetalhesSistema} />

        <AdminRoute exact path="/propostas_completas" component={Proposals} />
        <AdminRoute exact path="/propostas_completas/simular" component={ProposalSimulation} />

        <AuthRouteInternal exact path="/propostas" component={PropostasResumidas} />
        <AuthRouteInternal exact path="/propostas/simular" component={PropostasResumidasSimulacao} />

        <AdminRoute exact path="/propostas_branco" component={PropostasBranco} />

        <AuthRouteInternal exact path="/propostas_ev" component={PropostasEV} />
        <AuthRouteInternal exact path="/propostas_ev/simular" component={PropostasEVSimulacao} />

        <AuthRouteInternal exact path="/propostas_bat" component={PropostasBAT} />
        <AuthRouteInternal exact path="/propostas_bat/simular" component={PropostasBATSimulacao} />

        <AuthRouteInternal exact path="/propostas_gr" component={PropostasGR} />
        <AuthRouteInternal exact path="/propostas_gr/simular" component={PropostasGRSimulacao} />

        <AuthRouteInternal exact path="/propostas_om" component={PropostasOM} />
        <AuthRouteInternal exact path="/propostas_om/simular" component={PropostasOMSimulacao} />

        <AuthRouteInternal exact path="/propostas_nb" component={PropostasNB} />
        <AuthRouteInternal exact path="/propostas_nb/simular" component={PropostasNBSimulacao} />

        <AuthRouteInternal exact path="/propostas_ac" component={PropostasAC} />
        <AuthRouteInternal exact path="/propostas_ac/simular" component={PropostasACSimulacao} />

        <AuthRouteSuporte2 exact path="/painel_controle" component={PainelControle} />
        <AuthRouteSuporte2 exact path="/importar_tabela" component={ImportarTabela} />
        <AuthRouteSuporte2 exact path="/visualizar_tabela" component={VisualizarTabela} />
        <AuthRouteSuporte2 exact path="/comparar_tabela" component={CompararTabela} />

        <AuthRouteInternal exact path="/minha_conta" component={Profile} />


        <AdminRoute exact path="/RotaTeste" component={PrintACTeste} />


        {/* <AuthRouteInternal exact path="/ListaUsuarios" component={ListaUsuarios} /> */}
        {/* <AdminRoute exact path="/PrintTeste" component={PrintTeste} /> */}
        {/* <AdminRoute exact path="/datalogger" component={Datalogger} /> */}
        {/* <AuthRoute exact path="/RotaTeste" component={PrintTeste} /> */}
        {/* <Route exact path="/session" component={Session} /> */}

      </Switch>
    </BrowserRouter>
  )
}