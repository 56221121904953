// Referências externas
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { FiPrinter } from 'react-icons/fi';

// Referências internas
import pagina1 from './print_images/Prancheta EV.svg';

// Estilo
import "./styles.css";
import "./pagina1.css";

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo_cliente: 'ERRO',
      identificacao_proposta: 0,
      data_proposta: 0,
      validade_proposta: 0,
      
      sistema_outros: [
        {
          "item":"ERRO",
          "tipo":"ERRO",
          "preco_com_frete":0,
          "quantidade":0
        }
      ],

      valor_final: 0,

      termos_condicoes: [
        "ERRO",
        "ERRO",
        "ERRO"
      ],

      contato: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],

      assinatura_cliente: [
        "ERRO Cliente"
      ],

      assinatura_responsavel: [
        "ERRO Responsável"
      ]
    };
  }

  formatarDadosImpressao(propostaImpressa, dadosRoute) {
    // Dados enviados para o componente
    let objetoSaida = {};
    
    // CABEÇALHO
    let vocativo_proposta;
    if (propostaImpressa.titulo_cliente !== "N/A") {
      vocativo_proposta = (propostaImpressa.titulo_cliente + " " + propostaImpressa.nome_razao_cliente);
    } else {
      vocativo_proposta = (propostaImpressa.nome_razao_cliente);
    }
    objetoSaida.titulo_cliente = vocativo_proposta;

    if (propostaImpressa.revisao_proposta > 1) {
      objetoSaida.identificacao_proposta = `${propostaImpressa.numero_proposta}_EV_v${propostaImpressa.revisao_proposta}`;
    } else {
      objetoSaida.identificacao_proposta = `${propostaImpressa.numero_proposta}_EV`;
    }
    
    objetoSaida.data_proposta = (new Date(propostaImpressa.data_apresentacao_proposta)).toLocaleDateString();

    objetoSaida.validade_proposta = "15 dias";

    objetoSaida.sistema_outros = this.props.propostaImpressa.sistema_outros
    .filter(x => x.quantidade > 0)
    .map(x => (
      {
        ...x,
        preco_com_frete: (x.preco_com_frete * this.props.propostaImpressa.valor_final) / this.props.propostaImpressa.parcial_produto
      }
    ))

    objetoSaida.valor_final =  this.props.propostaImpressa.valor_final;

    objetoSaida.termos_condicoes = [
      "Termos e Condições",
      "Não está incluso a instalação e startup dos equipamentos.",
      // "Prazo de entrega: 4 dias úteis"
    ];

    objetoSaida.contato = [
      "Entre em contato",
      propostaImpressa.nome_usuario,
      propostaImpressa.titulo_usuario,
      propostaImpressa.telefone1_usuario,
      propostaImpressa.telefone2_usuario,
      propostaImpressa.email_usuario,
    ];

    objetoSaida.assinatura_responsavel = [
      propostaImpressa.assinatura_usuario,
      "Brazil Solution Serviços LTDA - CNPJ 19.853.758-0001/09"
    ];
    
    function mascara_assinatura_cliente(documento) {
      let cleanValue = documento.replace(/\D/g, '');

      if (cleanValue.length <= 11) {
        return ' - CPF ' + cleanValue
          .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

      } else {
          return ' - CNPJ ' + cleanValue
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
      }
    }

    objetoSaida.assinatura_cliente = [
        vocativo_proposta + mascara_assinatura_cliente(propostaImpressa.documento_cliente)
    ];

    return objetoSaida;
  }

  componentDidMount() {
    if (this.props.propostaImpressa && Object.keys(this.props.propostaImpressa).length > 0) {
      this.setState(
        this.formatarDadosImpressao(this.props.propostaImpressa, this.props.dadosRoute)
      );
    }
  }

  render() {
    return (
      <div>
        <div>
          <div className="page" style={{ margin: "0", padding: "0" }}>
            <img className="hidden" src={pagina1} height='100%'/>

            <div className="hidden">

              <ul className="cabecalho">
                <li> <div> {this.state.titulo_cliente} </div> </li>
                <li> <div> {this.state.identificacao_proposta} </div> </li>
                <li> <div> {this.state.data_proposta} </div> </li>
                <li> <div> {this.state.validade_proposta} </div> </li>
              </ul>

              <table className="sistema_outros_EV">
                <thead>
                  {this.state.sistema_outros.map((item, indice) => {return (
                      <tr key={item.item}>
                        <td>{(indice < 10) ? "0" + (indice + 1) : (indice + 1)}</td>
                        <td>{item.item}</td>
                        <td>R$ {item.preco_com_frete.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        <td>{item.quantidade}</td>
                        <td>R$ {(item.preco_com_frete * item.quantidade).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                  )})}
                </thead>
              </table>

              <table className={"sistema_outros_total_EV margem_" + this.state.sistema_outros.length + "_item"}>
                <thead>
                  <tr>
                    <td>Total</td>
                    <td>
                      R$ {this.state.valor_final.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </td>
                  </tr>
                </thead>
              </table>

              <ul className={"termos_condicoes margem_" + this.state.sistema_outros.length + "_item"}>
                {this.state.termos_condicoes.map((inversor, indice) => {
                  return ( <li key={indice}>{inversor}</li> )
                })}
              </ul>

              <ul className={"contato-EV margem_" + this.state.sistema_outros.length + "_item"}>
                {this.state.contato.map((inversor, indice) => {
                  return ( <li key={indice}>{inversor}</li> )
                })}
              </ul>

              <table className={"assinatura_cliente_EV margem_" + this.state.sistema_outros.length + "_item"}>
                <thead>
                  {this.state.assinatura_cliente.map((linha) => {return (
                      <tr key={linha}>
                        <td>{linha}</td>
                      </tr>
                  )})}
                </thead>
              </table>

              <table className={"assinatura_responsavel_EV margem_" + this.state.sistema_outros.length + "_item"}>
                <thead>
                  {this.state.assinatura_responsavel.map((linha) => {return (
                      <tr key={linha}>
                        <td>{linha}</td>
                      </tr>
                  )})}
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ProposalPrint extends Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button disabled={this.props.unsaved}> <span><FiPrinter/> Imprimir </span></button>}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          propostaImpressa = {this.props.propostaImpressa}
          dadosRoute = {this.props.dadosRoute}
          ref={(el) => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default ProposalPrint;

