// Referências externas
import React, { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import ClientForm from './ClientForm';
import { Mascara, RemoverMascara } from './MascaraDocumento';
import { Header, Loading, DynamicLoading } from '../../../common';

// Importar componente botão verde girando branco

// Estilo
import '../styles.css';










export default function (props) {
    const history = useHistory();

    // Dados enviados para <ClientForm />
    const [clienteExistente, setClienteExistente] = useState({});

    // Dados recebidos de <ClientForm/>
    const [tituloPagina, setTituloPagina] = useState('');
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarUsuarioExistente = () => {
        toast.error('Cliente já cadastrado!', {
            position: "bottom-center",
            autoClose: 10_000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    
    const notificarErroGenerico = (mensagem) => {
        toast.error(mensagem, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function verificar_cliente_existente() {
        if (String(props.clienteExibido.id_usuario).length !== 0) {
            setTituloPagina('Editar cliente');

            setClienteExistente({
                ...props.clienteExibido,
                documento_cliente_mascara: Mascara(props.clienteExibido.documento_cliente)
            })

        } else {
            setTituloPagina('Novo cliente');

            setClienteExistente({})
        }
    }

    function manipularHistory(string) {
        history.push(string);
    }

    useEffect(() => {
        verificar_cliente_existente();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className="modal-fundo">
            <div className="modal-cliente">
                <div className="modal-conteudo2">
                    <div className="modal-header">
                        <h2 className="wrapper-titulo">{tituloPagina}</h2>
                        <button className="botao-fechar-modal" onClick={() => props.fecharModalCliente()} >
                            <FiX />
                        </button>
                    </div>
                    <div className="">
                        {props.listaClientesCarregando || props.rotaDadosCarregando ? (
                            <div>
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <ClientForm
                                        clienteExistente={clienteExistente}
                                        parentProps={props}
                                        notificarUsuarioExistente={notificarUsuarioExistente}
                                        notificarErro={notificarErro}
                                        notificarErroPersonalizado={notificarErroPersonalizado}
                                        notificarErroGenerico={notificarErroGenerico}
                                        history={history}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}