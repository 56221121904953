// Referências externas
import { useState, useEffect } from 'react';

// Referências internas
import { Mascara, RemoverMascara } from './MascaraDocumento';
import api from '../../../services/api';

const useClientForm = (
    validateClientInfo,
    history,
    notificarUsuarioExistente,
    notificarErro,
    notificarErroGenerico,
    setSalvarClienteCarregando,
    fecharModalCliente,
    customers,
    setCustomers
  ) => {
  const [values, setValues] = useState({
    ID_cliente: 0,
    titulo_cliente: '',
    nome_razao_cliente: '',
    documento_cliente: '',
    documento_cliente_mascara: '',
    id_usuario: ""
  });

  const [errors, setErrors] = useState({});

  function setValueObject(object) {
    // console.log('setValueObject ↓')
    // console.log(object);
    const novoValor = {
      ...values,
      ...object
    }

    setValues(novoValor);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    const novoValor = {
      ...values,
      [name]: value
    }

    setValues(novoValor);
  };

  function handleChangeDocumento(e) {
    const { value } = e.target;
    const novoValor = {
      ...values,
      ['documento_cliente']: RemoverMascara(value),
      ['documento_cliente_mascara']: Mascara(value)
    }

    setValues(novoValor);
  }

  function handleChangeResponsavel(e) {
    const { name, value } = e.target;
    const novoValor = {
      ...values,
      [name]: value
    }

    if (value !== '' && value !== "0") {
      setValues(novoValor);
    }
  }

  function handleChangeNomeRazaoCliente(e) {
    const { name, value } = e.target;

    const valueRegExp = value.replace(new RegExp('[^a-záàâãéèêíïóôõöúçñ]+[\s]+','gi'), '')

    const novoValor = {
      ...values,
      [name]: valueRegExp
    }

    // console.log(valueRegExp)
    setValues(novoValor);
  }

  async function carregarClienteInserido(ID_cliente) {
    try {
      const result = await api.get(('clientes/' + ID_cliente));
      return result.data[0];

    } catch (error) {
      notificarErro();
    }
  }

  async function salvar(e, modo, origem) {
    e.preventDefault();
    let erros = validateClientInfo(values);
    setErrors(erros);

    if (Object.keys(erros).length === 0) {
      let ID_cliente = 0;

      try {
        setSalvarClienteCarregando(true);

        if (values.ID_cliente === 0) {
          // Salvar novo cliente
          const clienteSalvo = await api.post('clientes', {
              titulo_cliente: values.titulo_cliente,
              nome_razao_cliente: values.nome_razao_cliente,
              documento_cliente: values.documento_cliente,
              id_usuario: values.id_usuario
          });
          
          ID_cliente = clienteSalvo.data.insertId;
          const clienteInserido = await carregarClienteInserido(ID_cliente);

          setCustomers([
            clienteInserido,
            ...customers
          ]);

          // console.log(customers)
        } else if (values.ID_cliente !== 0) {
          // Editar cliente
          ID_cliente = values.ID_cliente;

          const novosValores = {
            ID_cliente: ID_cliente,
            titulo_cliente: values.titulo_cliente,
            nome_razao_cliente: values.nome_razao_cliente,
            documento_cliente: values.documento_cliente,
            id_usuario: values.id_usuario
          };
          
          await api.patch('clientes', novosValores);

          setCustomers(customers.map(
            x => (
              x.ID_cliente === ID_cliente ? {...x, ...novosValores} : x
            )
          ));
        }

        if (modo === 'salvarEGerarProposta') {
          const clienteInserido = await carregarClienteInserido(ID_cliente);
          
          history.push({
            pathname: `${origem}/simular`,
            origin: `${origem}`,
            data:  clienteInserido
          });

        } else if (modo === 'salvarApenas') {
          history.push('/clientes');
        }

        setSalvarClienteCarregando(false);
        fecharModalCliente();

      } catch (error) {
        setSalvarClienteCarregando(false);

        if (error.message === 'Request failed with status code 409') {
          notificarUsuarioExistente();
        } else {
          notificarErro();
        }
      }
    } else {
      notificarErroGenerico('Erro no envio de dados');
    }
  }

  return {
    setValueObject,
    handleChange,
    handleChangeDocumento,
    handleChangeResponsavel,
    handleChangeNomeRazaoCliente,
    salvar,
    values,
    errors,
    setErrors
  };
};

export default useClientForm;