// Referências externas
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from "react-cookie";
import { FiChevronDown } from 'react-icons/fi'


// Estilo
import './styles.css';

function NavItem(props) {
    return (
        <li className="nav-item-botao-salvar-como">
            <div className="wrapper-botoes">
                <button className="botao-opcao-padrao" onClick={() => props.salvarProposta()} disabled={props.salvarBloqueado}>
                    <span> Salvar {props.salvarBloqueado} </span>
                </button>
                
                <button className="botao-mais-opcoes" onClick={() => props.setOpen(!props.open)} disabled={props.salvarBloqueado}>
                    {props.salvarBloqueado ? (
                        <FiChevronDown color={'rgba(16, 16, 16, 0.3)'} size={18}/>
                    ):(
                        <FiChevronDown color={'#000'} size={18}/>
                    )}
                </button>
            </div>

            {props.open && props.children}
        </li>
    );
}

function DropdownMenu(props) {
    const [removeCookie] = useCookies(["sessionId"]);

    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
    }, [])

    const history = useHistory();

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    function DropdownItem(props) {
        return (
            <button
                href=""
                className="salvar-opcao"
                onClick={() => {props.setModalSalvarComoAberto(true); props.setOpen(false);}}
            >
                {props.children}
            </button>
        );
    }

    return (
        <div className="dropdownBotaoSalvar" style={{ height: menuHeight }} ref={dropdownRef}>
            <CSSTransition
                in={activeMenu === 'main'}
                timeout={500}
                classNames="menu-primary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    <DropdownItem setOpen={props.setOpen} setModalSalvarComoAberto={props.setModalSalvarComoAberto}>
                        Salvar como
                    </DropdownItem>
                </div>
            </CSSTransition>
        </div>
    );
}

export default function BotaoSalvarComo(props) {
    const [open, setOpen] = useState(false);
    
    return (
        <NavItem open={open} setOpen={setOpen} salvarProposta={props.salvarProposta} salvarBloqueado={props.salvarBloqueado}>
            <DropdownMenu setOpen={setOpen} setModalSalvarComoAberto={props.setModalSalvarComoAberto} salvarProposta={props.salvarProposta}></DropdownMenu>
        </NavItem>
    );
}