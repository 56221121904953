import React from 'react';
import Lottie from 'react-lottie';
import * as loading from '../../assets/7774-loading.json';
import './styles.css';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export default function LoadingBranco () {

    return (
        <div className="loading-wraper">
            <div className="loading">
                <p>Por favor aguarde, carregando dados.</p>
                <Lottie options={defaultOptions} height={120} width={120} />
            </div>
        </div>
    )
}