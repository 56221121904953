import React from 'react';

import './global.css';
import './modal.css';

// import Login from './pages/Login';

import Routes from './routes'

function App() {
  return (
    <Routes />
  );
}

export default App;
