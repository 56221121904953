// Referências externas
import React from 'react';

const ItemOM = ( props ) => {
  return (
    <div className='plano-disponivel-om' key={props.plano.nome}>
      <table>
        <tbody>
          <th>{props.plano.nome}</th>
          <tr>1</tr>
          <tr>2</tr>

        </tbody>
      </table>
    </div>
  );
};

export default ItemOM;