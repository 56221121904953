// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiArrowLeft } from 'react-icons/fi';

// Referências internas
import { Header } from '../../common';
// import api from '../../services/api';
import { FileUploader } from './components/FileUploader';

// Estilo
import './styles.css';

// Referências internas
// const permissaoUsuario = require('../../services/permissaoUsuario');

export const ImportarTabela = props => {
    // const [usuarios, setUsuarios] = useState([]);
    // const [done, setDone] = useState(true);
    // const [token, setToken] = useState({});

    // const [buscaListaUsuarios, setBuscaListaUsuarios] = useState('');

    const history = useHistory();

    
    const [files, setFiles] = useState([]);
    const onSuccess = (savedFiles) => {
        setFiles(savedFiles)
    };




    // function normalizar_texto(texto) {
    //     texto = texto.toString();
    //     texto = texto.toLowerCase();
    //     texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    //     texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    //     texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    //     texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    //     texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    //     texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
    //     return texto;
    // }
    
    // const notificarErro = () => {
    //     toast.error('Erro ao estabelecer conexão com o servidor', {
    //         position: "bottom-center",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    // }


    useEffect(() => {
        document.title = `SGC Brazil Solution`;
        // setToken(JSON.parse(localStorage.getItem('sgc_br')));

        // async function solicitarUsuarios() {
        //     setDone(false);
        //     try {
        //         const response = await api.get('dados/lista_usuarios', {
        //             timeout: 15000
        //         });

        //         setUsuarios(response.data);
        //     } catch (error) {
        //         notificarErro();
        //     }
        //     setDone(true);

        // }

        // solicitarUsuarios();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            <div>Importar tabela</div>
                        </div>

                        <button className="type1-main-button" onClick={() => history.push('/painel_controle')} >
                            <FiArrowLeft/>
                            <span>Voltar</span>
                        </button>
                    </div>
                    <div className="importar-content">
                        <FileUploader props={props} onSuccess={onSuccess}/>
                        <ToastContainer/>
                    </div>
                </div>
            </div>
        </div>
        
    );
}