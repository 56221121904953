// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading } from '../../common';
import api from '../../services/api';

// Estilo
import './styles.css';

export default function PropostasResumidas() {
    const history = useHistory();
    
    const [proposals, setProposals] = useState([]);
    const [done, setDone] = useState(true);

    const [buscaListaPropostas, setBuscaListaPropostas] = useState('');

    async function obterPropostaCompleta(propostaSelecionadaResumida) {
        const numero_proposta = propostaSelecionadaResumida.numero_proposta;
        const revisao_proposta = propostaSelecionadaResumida.revisao_proposta;

        const response = await api.get(`propostas/resumo_sistemas_detalhes/${numero_proposta}/${revisao_proposta}`);
        const propostaCompleta = response.data;

        return propostaCompleta[0];
    }

    function vocativo(proposta) {
        if(proposta.titulo_cliente !== "N/A") {
            return proposta.titulo_cliente + " " + proposta.nome_razao_cliente;

        } else {
            return proposta.nome_razao_cliente;
        }
    }

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function editarProposta(propostaSelecionadaResumida) {
        const propostaSelecionada = await obterPropostaCompleta(propostaSelecionadaResumida);

        const id = {
            ID_cliente: propostaSelecionada.ID_cliente,
            titulo_cliente: propostaSelecionada.titulo_cliente,
            nome_razao_cliente: propostaSelecionada.nome_razao_cliente
        }

        history.push({
            pathname: `sistemas/detalhes_sistemas`,
            origin: 'sistemas',
            data:  id,
            proposal: propostaSelecionada
        })        
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        async function solicitarPropostasEClientes() {
            setDone(false);
            try {
                const response = await api.get('propostas/resumo_sistemas');
                // console.log(response);
                setProposals(response.data);
            } catch (error) {
                notificarErro();
            }
            setDone(true);
        }

        solicitarPropostasEClientes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="body-container">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            <div>RESUMO SISTEMAS</div>
                        </div>

                        <input
                            autoFocus
                            name="busca_lista_proposta"
                            className="text-input-search"
                            type="text"
                            id="busca_lista_proposta"
                            placeholder="Pesquisar"
                            onChange={e => { setBuscaListaPropostas(e.target.value); }}
                            autoComplete="off"
                        />

                        <div style={{width: '225px'}}></div>
                    </div>

                    <div className="type3-content">
                        {!done ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (
                            <table className="proposals-table">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Revisão</th>
                                        <th>Cliente</th>
                                        <th>Data</th>
                                        <th>Local Instalação</th>
                                        <th>Sistema</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {proposals
                                        .filter(proposal => {
                                            if (buscaListaPropostas.length > 0 && buscaListaPropostas.replace(new RegExp('[0-9]','gi'), '') === '') {
                                                const proposta_atual_normalizada = normalizar_texto(proposal.numero_proposta);
                                                const proposta_desejada_normalizada = normalizar_texto(buscaListaPropostas);

                                                if (proposta_atual_normalizada.includes(proposta_desejada_normalizada)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            } else {
                                                const cliente_atual_normalizado = normalizar_texto(proposal.nome_razao_cliente);
                                                const cliente_desejado_normalizado = normalizar_texto(buscaListaPropostas);

                                                if (cliente_atual_normalizado.includes(cliente_desejado_normalizado)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            }
                                        })
                                        .map(proposal => {
                                            let data_apresentacao_proposta = (new Date(proposal.data_apresentacao_proposta)).toLocaleDateString();

                                            return (
                                                <tr key={String(proposal.numero_proposta)+' '+String(proposal.revisao_proposta)}>

                                                    <td>{proposal.numero_proposta}</td>
                                                    <td>{proposal.revisao_proposta}</td>
                                                    <td>{vocativo(proposal)}</td>
                                                    <td>{data_apresentacao_proposta}</td>
                                                    <td>{(proposal.aplicacao_estrutura)}</td>                                    
                                                    <td>{proposal.sistema_sugerido.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 4})} kWp</td>

                                                    <td>
                                                        <div className="type4-functions">
                                                            <div><button className="view-button1" onClick={() => editarProposta(proposal)}>
                                                                Visualizar
                                                            </button></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>

                            </table>
                    
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
}