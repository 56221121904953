// Referências externas
import React from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';

const ItemNoBreak = ( props ) => {
  return (
    <tr key={props.dados.item}>
        <td>
          <div className="quantidade-estacao-veicular">
            <button onClick={() => props.diminuirQuantidade(props.dados)}><FiMinusCircle size={15}/></button>
            <div>
              {parseInt(props.dados.quantidade).toLocaleString('pt-BR', {style: 'decimal'})}
            </div>
            <button onClick={() => props.aumentarQuantidade(props.dados)}><FiPlusCircle size={15}/></button>
          </div>
        </td>
        <td>{props.dados.item}</td>
        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.dados.preco_com_frete)}</td>
        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.dados.quantidade * props.dados.preco_com_frete)}</td>
        <td style={{borderTop: "1px solid #fff", borderBottom: "1px solid #fff"}}></td>
        <td><input type="number" style={{textAlign: "center"}} value={props.estadoProposta.margem_proposta} disabled/></td>
        <td>
          {props.dados.quantidade > 0 ?
          (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.dados.preco_com_frete * props.estadoProposta.valor_final / props.estadoProposta.parcial_produto))
          :
          (Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0))}
        </td>
    </tr>
  );
};

export default ItemNoBreak;