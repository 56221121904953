export default function validarEstado(estadoFormulario) {
  let erros = {};

  if (!estadoFormulario.tabela.trim()) {
    erros.tabela = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (!estadoFormulario.concessionaria.trim()) {
    erros.concessionaria = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (!estadoFormulario.tipo_inversor.trim()) {
    erros.tipo_inversor = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (!estadoFormulario.responsavel.trim()) {
    erros.responsavel = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.tarifa === "") {
    erros.tarifa = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.tarifa < 0) {
    erros.tarifa = 'Valor inválido';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.tarifa > 2) {
    erros.tarifa = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.comissao === "") {
    erros.comissao = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.comissao < 0 || estadoFormulario.comissao > 25) {
    erros.comissao = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.percentual_atendido === "") {
    erros.percentual_atendido = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.percentual_atendido <= 0) {
    erros.percentual_atendido = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.valor_fixo === "") {
    erros.valor_fixo = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.valor_fixo < 0) {
    erros.valor_fixo = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.injecao === "") {
    erros.injecao = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.injecao > 100) {
    erros.injecao = 'Valor inválido';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.injecao < 0) {
    erros.injecao = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.icms_tarifa === "") {
    erros.icms_tarifa = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.icms_tarifa > 35) {
    erros.icms_tarifa = 'Valor inválido';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.icms_tarifa < 0) {
    erros.icms_tarifa = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.parcela_tusd === "") {
    erros.parcela_tusd = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.parcela_tusd > 100) {
    erros.parcela_tusd = 'Valor inválido';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.parcela_tusd < 30) {
    erros.parcela_tusd = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.parcela_fiob === "") {
    erros.parcela_fiob = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.parcela_fiob > 75) {
    erros.parcela_fiob = 'Valor inválido';
    erros.geral = 'Valor inválido';
  } else if (estadoFormulario.parcela_fiob < 15) {
    erros.parcela_fiob = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }
  
  if (!estadoFormulario.aplicacao_estrutura.trim()) {
    erros.aplicacao_estrutura = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (!estadoFormulario.modulo_modelo.trim()) {
    erros.modulo_modelo = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.outros_valores < 0) {
    erros.outros_valores = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.margem_proposta < 0 || estadoFormulario.margem_proposta > 100) {
    erros.margem_proposta = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.material_proposta < 0 || estadoFormulario.material_proposta > 100) {
    erros.material_proposta = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.desconto_weg_proposta.length !== 4
    || estadoFormulario.desconto_weg_proposta[0] < 0
    || estadoFormulario.desconto_weg_proposta[0] > 100

    || estadoFormulario.desconto_weg_proposta[1] < 0
    || estadoFormulario.desconto_weg_proposta[1] > 100

    || estadoFormulario.desconto_weg_proposta[2] < 0
    || estadoFormulario.desconto_weg_proposta[2] > 100

    || estadoFormulario.desconto_weg_proposta[3] < 0
    || estadoFormulario.desconto_weg_proposta[3] > 100) {
      erros.desconto_weg_proposta = 'Valor inválido';
      erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.imposto_proposta < 0 || estadoFormulario.imposto_proposta > 100) {
    erros.imposto_proposta = 'Valor inválido';
    erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.consumo_medio === "") {
    erros.consumo_medio = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_medio)) {
    erros.consumo_medio = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_medio <= 0) {
    erros.consumo_medio = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_janeiro === "") {
    erros.consumo_janeiro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_janeiro)) {
    erros.consumo_janeiro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_janeiro <= 0) {
    erros.consumo_janeiro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_fevereiro === "") {
    erros.consumo_fevereiro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_fevereiro)) {
    erros.consumo_fevereiro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_fevereiro <= 0) {
    erros.consumo_fevereiro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_marco === "") {
    erros.consumo_marco = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_marco)) {
    erros.consumo_marco = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_marco <= 0) {
    erros.consumo_marco = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_abril === "") {
    erros.consumo_abril = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_abril)) {
    erros.consumo_abril = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_abril <= 0) {
    erros.consumo_abril = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_maio === "") {
    erros.consumo_maio = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_maio)) {
    erros.consumo_maio = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_maio <= 0) {
    erros.consumo_maio = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_junho === "") {
    erros.consumo_junho = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_junho)) {
    erros.consumo_junho = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_junho <= 0) {
    erros.consumo_junho = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_julho === "") {
    erros.consumo_julho = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_julho)) {
    erros.consumo_julho = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_julho <= 0) {
    erros.consumo_julho = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_agosto === "") {
    erros.consumo_agosto = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_agosto)) {
    erros.consumo_agosto = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_agosto <= 0) {
    erros.consumo_agosto = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_setembro === "") {
    erros.consumo_setembro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_setembro)) {
    erros.consumo_setembro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_setembro <= 0) {
    erros.consumo_setembro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_outubro === "") {
    erros.consumo_outubro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_outubro)) {
    erros.consumo_outubro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_outubro <= 0) {
    erros.consumo_outubro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_novembro === "") {
    erros.consumo_novembro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_novembro)) {
    erros.consumo_novembro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_novembro <= 0) {
    erros.consumo_novembro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_dezembro === "") {
    erros.consumo_dezembro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_dezembro)) {
    erros.consumo_dezembro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_dezembro <= 0) {
    erros.consumo_dezembro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.relacao_geracao <= 0 || estadoFormulario.relacao_geracao >= 200) {
    erros.relacao_geracao = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_janeiro === "") {
    erros.geracao_janeiro = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_janeiro <= 0) {
    erros.geracao_janeiro = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_fevereiro === "") {
    erros.geracao_fevereiro = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_fevereiro <= 0) {
    erros.geracao_fevereiro = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_marco === "") {
    erros.geracao_marco = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_marco <= 0) {
    erros.geracao_marco = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_abril === "") {
    erros.geracao_abril = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_abril <= 0) {
    erros.geracao_abril = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_maio === "") {
    erros.geracao_maio = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_maio <= 0) {
    erros.geracao_maio = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_junho === "") {
    erros.geracao_junho = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_junho <= 0) {
    erros.geracao_junho = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_julho === "") {
    erros.geracao_julho = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_julho <= 0) {
    erros.geracao_julho = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_agosto === "") {
    erros.geracao_agosto = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_agosto <= 0) {
    erros.geracao_agosto = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_setembro === "") {
    erros.geracao_setembro = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_setembro <= 0) {
    erros.geracao_setembro = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_outubro === "") {
    erros.geracao_outubro = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_outubro <= 0) {
    erros.geracao_outubro = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_novembro === "") {
    erros.geracao_novembro = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_novembro <= 0) {
    erros.geracao_novembro = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  if (estadoFormulario.geracao_dezembro === "") {
    erros.geracao_dezembro = 'Campo obrigatório';
    erros.geracao = 'Valor inválido';
  } else if (estadoFormulario.geracao_dezembro <= 0) {
    erros.geracao_dezembro = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }

  return erros;

  
  // if (!estadoFormulario.email) {
  //   erros.email = 'Email required';
  // } else if (!/\S+@\S+\.\S+/.test(estadoFormulario.email)) {
  //   erros.email = 'Email address is invalid';
  // }
  // else if (!/^[A-Za-z]+/.test(estadoFormulario.name.trim())) {
  //   erros.name = 'Enter a valid name';
  // }
  // 
  // if (!estadoFormulario.password) {
  //   erros.password = 'Password is required';
  // } else if (estadoFormulario.password.length < 6) {
  //   erros.password = 'Password needs to be 6 characters or more';
  // }

  // if (!estadoFormulario.password2) {
  //   erros.password2 = 'Password is required';
  // } else if (estadoFormulario.password2 !== estadoFormulario.password) {
  //   erros.password2 = 'Passwords do not match';
  // }
}