export default function validarEstado(estadoFormulario) {
  let erros = {};

  if (!estadoFormulario.concessionaria.trim()) {
    erros.concessionaria = 'Campo obrigatório';
    erros.geral = 'Valor concessionaria inválido';
  }

  if (!estadoFormulario.responsavel.trim()) {
    erros.responsavel = 'Campo obrigatório';
    erros.geral = 'Valor inválido';
  }
  
  if (estadoFormulario.tarifa === "") {
    erros.tarifa = 'Campo obrigatório';
    erros.geral = 'Valor tarifa inválido';
  } else if (estadoFormulario.tarifa < 0) {
    erros.tarifa = 'Valor inválido';
    erros.geral = 'Valor tarifa inválido';
  } else if (estadoFormulario.tarifa > 2) {
    erros.tarifa = 'Valor inválido';
    erros.geral = 'Valor tarifa inválido';
  }

  

  if (estadoFormulario.comissao === "") {
    erros.comissao = 'Campo obrigatório';
    erros.comercial = 'Valor comissao inválido';
  } else if (estadoFormulario.comissao < 0 || estadoFormulario.comissao > 25) {
    erros.comissao = 'Valor inválido';
    erros.comercial = 'Valor comissao inválido';
  }

  if (estadoFormulario.percentual_atendido === "") {
    erros.percentual_atendido = 'Campo obrigatório';
    erros.geral = 'Valor percentual_atendido inválido';
  } else if (estadoFormulario.percentual_atendido < 0) {
    erros.percentual_atendido = 'Valor inválido';
    erros.geral = 'Valor percentual_atendido inválido';
  }

  if (estadoFormulario.valor_fixo === "") {
    erros.valor_fixo = 'Campo obrigatório';
    erros.geral = 'Valor valor_fixo inválido';
  } else if (estadoFormulario.valor_fixo < 0) {
    erros.valor_fixo = 'Valor inválido';
    erros.geral = 'Valor valor_fixo inválido';
  }

  if (!estadoFormulario.aplicacao_estrutura.trim()) {
    erros.aplicacao_estrutura = 'Campo obrigatório';
    erros.geral = 'Valor aplicacao_estrutura inválido';
  }

  if (estadoFormulario.desconto_weg_proposta.length !== 4
    || estadoFormulario.desconto_weg_proposta[0] < 0
    || estadoFormulario.desconto_weg_proposta[0] > 100

    || estadoFormulario.desconto_weg_proposta[1] < 0
    || estadoFormulario.desconto_weg_proposta[1] > 100

    || estadoFormulario.desconto_weg_proposta[2] < 0
    || estadoFormulario.desconto_weg_proposta[2] > 100

    || estadoFormulario.desconto_weg_proposta[3] < 0
    || estadoFormulario.desconto_weg_proposta[3] > 100) {
      erros.desconto_weg_proposta = 'Valor inválido';
      erros.geral = 'Valor inválido';
  }

  if (estadoFormulario.consumo_medio === "") {
    erros.consumo_medio = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (estadoFormulario.consumo_medio <= 0) {
    erros.consumo_medio = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.relacao_geracao <= 0 || estadoFormulario.relacao_geracao >= 200) {
    erros.relacao_geracao = 'Valor inválido';
    erros.geracao = 'Valor inválido';
  }


  
  if (estadoFormulario.consumo_janeiro === "") {
    erros.consumo_janeiro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_janeiro)) {
    erros.consumo_janeiro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_janeiro <= 0) {
    erros.consumo_janeiro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_fevereiro === "") {
    erros.consumo_fevereiro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_fevereiro)) {
    erros.consumo_fevereiro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_fevereiro <= 0) {
    erros.consumo_fevereiro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_marco === "") {
    erros.consumo_marco = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_marco)) {
    erros.consumo_marco = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_marco <= 0) {
    erros.consumo_marco = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_abril === "") {
    erros.consumo_abril = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_abril)) {
    erros.consumo_abril = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_abril <= 0) {
    erros.consumo_abril = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_maio === "") {
    erros.consumo_maio = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_maio)) {
    erros.consumo_maio = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_maio <= 0) {
    erros.consumo_maio = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_junho === "") {
    erros.consumo_junho = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_junho)) {
    erros.consumo_junho = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_junho <= 0) {
    erros.consumo_junho = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_julho === "") {
    erros.consumo_julho = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_julho)) {
    erros.consumo_julho = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_julho <= 0) {
    erros.consumo_julho = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_agosto === "") {
    erros.consumo_agosto = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_agosto)) {
    erros.consumo_agosto = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_agosto <= 0) {
    erros.consumo_agosto = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_setembro === "") {
    erros.consumo_setembro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_setembro)) {
    erros.consumo_setembro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_setembro <= 0) {
    erros.consumo_setembro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_outubro === "") {
    erros.consumo_outubro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_outubro)) {
    erros.consumo_outubro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_outubro <= 0) {
    erros.consumo_outubro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_novembro === "") {
    erros.consumo_novembro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_novembro)) {
    erros.consumo_novembro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_novembro <= 0) {
    erros.consumo_novembro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  if (estadoFormulario.consumo_dezembro === "") {
    erros.consumo_dezembro = 'Campo obrigatório';
    erros.consumo = 'Valor inválido';
  } else if (isNaN(estadoFormulario.consumo_dezembro)) {
    erros.consumo_dezembro = 'Erro NaN';
    erros.consumo = 'Valor não numérico';
  } else if (estadoFormulario.consumo_dezembro <= 0) {
    erros.consumo_dezembro = 'Valor inválido';
    erros.consumo = 'Valor inválido';
  }

  

  

  return erros;
  
}