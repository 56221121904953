// Referências externas
import React, { useState } from 'react';
import { FiX, FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import { Loading, DynamicLoading } from '../../../common';

export default function ModalNovaPropostaBranco(props) {
    const [buscaListaClientes, setBuscaListaClientes] = useState('');

    return (
        <div className="modal-fundo">
            <div className="modal-nova-proposta-branco">
                <div className="modal-conteudo2">
                    <div className="modal-proposta-branco-titulo">
                        <h2 className="wrapper-titulo-proposta-branco">Nova proposta em branco</h2>
                        <button className="botao-fechar-modal" onClick={() => props.fechar()} >
                            <FiX />
                        </button>
                    </div>
                    {props.listasCarregando ? (
                        <div>
                            <Loading />
                        </div>
                    )  :  (
                        <div className="modal-proposta-branco-corpo">

                            <div className="modal-proposta-branco-selecao-responsavel">
                                <h4>Agente Comercial Responsável {props.errosModal.id_usuario_responsavel_proposta && <FiAlertTriangle size={12} color="#ff0000"/>} </h4>

                                <select name="responsavel" onChange={e => { props.setDadosModal({...props.dadosModal, "id_usuario_responsavel_proposta": e.target.value}); }} >
                                    <option value="0">Selecione</option>
                                    {props.responsaveis
                                        .map(responsavel => (
                                            <option key={responsavel.id_usuario} value={responsavel.id_usuario}>{responsavel.nome_usuario}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <h4>Selecione um cliente {props.errosModal.id_cliente_proposta && <FiAlertTriangle size={12} color="#ff0000"/>} </h4>

                            <input
                                autoFocus
                                name="nome_razao_cliente"
                                className="text-input-search3"
                                type="text"
                                id="nome_razao_cliente"
                                placeholder="Pesquisar"
                                onChange={(e) => setBuscaListaClientes(String(e.target.value))} 
                                autoComplete="off"
                            />
                            
                            <div className="modal-proposta-branco-clientes">
                                {props.clientes
                                    .filter(cliente => {
                                        const cliente_atual_normalizado = props.normalizar_texto(cliente.nome_razao_cliente);
                                        const cliente_desejado_normalizado = props.normalizar_texto(buscaListaClientes);

                                        if (cliente_atual_normalizado.includes(cliente_desejado_normalizado)) {
                                            return cliente;
                                        }
                                    })
                                    .map(cliente => (
                                        <div key={cliente.ID_cliente}>
                                            <input
                                                onChange={(e) => {
                                                    props.setDadosModal({...props.dadosModal, "id_cliente_proposta": e.target.value});
                                                }}
                                                type="radio"
                                                name="id_cliente_proposta"
                                                id={cliente.ID_cliente}
                                                value={cliente.ID_cliente}
                                            />

                                            <label htmlFor={cliente.ID_cliente}>
                                                {cliente.titulo_cliente !== "N/A" ? (
                                                    cliente.titulo_cliente + " " + cliente.nome_razao_cliente
                                                )  :  (
                                                    cliente.nome_razao_cliente
                                                )}
                                            </label><br/>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="modal-proposta-branco-botoes">
                                <button className="modal-proposta-branco-cancelar" onClick={() => props.fechar()}>Cancelar</button>

                                {props.enviandoDadosModal ? (
                                    <button disabled className="modal-proposta-branco-salvar-carregando">
                                        <div><DynamicLoading /></div>
                                    </button>
                                ) : (
                                    <button className="modal-proposta-branco-salvar" onClick={() => props.salvarPropostaBranco()}>Salvar</button>
                                )}

                            </div>

                        </div>
                    )}
                
                </div>
            </div>
        </div>
    );
}