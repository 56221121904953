import { useState, useEffect } from 'react';

const useForm = (notificarErroPreenchimentoFormulario, estadoFormulario, setEstadoFormulario, restringirBotoes, callback, dadosPadrao, validar, ajustarNovaLista) => {
  const [erros, setErros] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Função acionada após alterar o campo "Desconto WEG(%)"
  function alteracao_desconto_WEG(e, indice) {
    if (indice > 0 && indice < 4) {
      restringirBotoes();

      let novoArrayDescontoWEG = estadoFormulario.desconto_weg_proposta;

      novoArrayDescontoWEG[indice] = parseFloat(e.target.value);

      setValueObject({
        desconto_weg_proposta: novoArrayDescontoWEG,
      });

    } else {
      throw (new Error("Índice inválido"));
    }
  }

  // Função acionada após alterar o campo "Concessionária"
  function alteracao_concessionaria(e, concessionarias) {
    restringirBotoes();

    const concessionaria_selecionada = e.target.value;
    const dados_concessionaria_selecionada = (concessionarias.filter(x => x.nome_concessionaria === concessionaria_selecionada))[0]
    
    if(concessionaria_selecionada !== ""){
      const { name, value } = e.target;
      const novoValor = {
        ...estadoFormulario,
        [name]: value,
        ['icms_tarifa']: 100 * Math.round(dados_concessionaria_selecionada.icms_regiao_concessionaria * 10_000) / 10_000,
        ['parcela_tusd']: 100 * Math.round(dados_concessionaria_selecionada.parcela_tusd_concessionaria * 10_000) / 10_000,
        ['parcela_fiob']: 100 * Math.round(dados_concessionaria_selecionada.parcela_fiob_concessionaria * 10_000) / 10_000,
        ['tarifa']: Math.round(dados_concessionaria_selecionada.tarifa_padrao_concessionaria * 10_000) / 10_000
      }

      setEstadoFormulario(novoValor);
    }
  }

  // Função acionada após alterar o campo "Agente Comercial"
  function alteracao_responsavel(e, responsaveis) {
    restringirBotoes();
    
    const responsavel_selecionado = e.target.value;
    const array_responsaveis = Object.assign({}, ...responsaveis.map((x) => ({[x.nome_usuario]: [x.id_usuario, x.comissao_usuario]})));
    if(responsavel_selecionado !== ""){
      const { name, value } = e.target;

      const novoValor = {
        ...estadoFormulario,
        [name]: value,
        ['id_responsavel']: array_responsaveis[responsavel_selecionado][0],
        ['comissao']: parseFloat(array_responsaveis[responsavel_selecionado][1].toFixed(2))
      }

      setEstadoFormulario(novoValor);
    }
  }


  // Revisar abaixo nomes e nomes banco tbm
  // Função acionada após alterar o campo "Tipo de telhado"
  function alteracao_estrutura(e, telhados) {
    restringirBotoes();
    
    const telhado_selecionado = e.target.value;
    const array_telhado = Object.assign({}, ...telhados.map((x) => ({[x.aplicacao_estrutura]: [x.tipo_estrutura]})));
    if(telhado_selecionado !== ""){
      const { name, value } = e.target;
      const novoValor = {
        ...estadoFormulario,
        ['aplicacao_estrutura']: value,
        ['tipo_estrutura']: array_telhado[telhado_selecionado][0]
      }

      setEstadoFormulario(novoValor);
    }
  }

  // Função acionada após alterar o campo "Módulo"
  function alteracao_modulo(e, modulos) {
    restringirBotoes();
    
    const modulo_selecionado = e.target.value;
    const array_modulos = Object.assign({}, ...modulos.map((x) => ({[x.modelo]: [x.potencia, x.area, x.peso, x.garantia1, x.garantia2, x.campo_adicional1]})));
    if(modulo_selecionado !== ""){
      const { name, value } = e.target;
      const novoValor = {
        ...estadoFormulario,
        [name]: value,
        ['modulo_potencia']: Math.round(array_modulos[modulo_selecionado][0] * 100) / 100,
        ['modulo_area']: array_modulos[modulo_selecionado][1],
        ['modulo_peso']: array_modulos[modulo_selecionado][2],
        ['modulo_garantia1']: array_modulos[modulo_selecionado][3],
        ['modulo_garantia2']: array_modulos[modulo_selecionado][4],
        ['campo_adicional1']: array_modulos[modulo_selecionado][5],
      }

      setEstadoFormulario(novoValor);
    }
  }

  // Função acionada após alterar o campo "Média de consumo"
  function alteracao_media(e) {
    restringirBotoes();

    const novo_consumo_medio = Math.round(parseFloat(e.target.value));
    const consumo_medio_atual = (estadoFormulario.curva_consumo.reduce((somatorio, valor) => somatorio + valor)) / 12;
    const taxa = novo_consumo_medio / consumo_medio_atual;

    const nova_curva_consumo = estadoFormulario.curva_consumo.map((x) => (x * taxa));
    const consumo_exibido = nova_curva_consumo.map((x) => (Math.round(x * 100) / 100));

    const novoValor = {
      ...estadoFormulario,
      ['consumo_medio']: novo_consumo_medio,
      ['curva_consumo']: nova_curva_consumo,
      ['consumo_janeiro']: consumo_exibido[0],
      ['consumo_fevereiro']: consumo_exibido[1],
      ['consumo_marco']: consumo_exibido[2],
      ['consumo_abril']: consumo_exibido[3],
      ['consumo_maio']: consumo_exibido[4],
      ['consumo_junho']: consumo_exibido[5],
      ['consumo_julho']: consumo_exibido[6],
      ['consumo_agosto']: consumo_exibido[7],
      ['consumo_setembro']: consumo_exibido[8],
      ['consumo_outubro']: consumo_exibido[9],
      ['consumo_novembro']: consumo_exibido[10],
      ['consumo_dezembro']: consumo_exibido[11]
    }

    setEstadoFormulario(novoValor);

  }

  // Função acionada após alterar o consumo de qualquer um dos 12 meses
  function alteracao_mes_consumo(e, indice) {
    restringirBotoes();

    const novo_consumo_mes = Math.round(parseFloat(e.target.value));

    const string_meses = [
      'consumo_janeiro',
      'consumo_fevereiro',
      'consumo_marco',
      'consumo_abril',
      'consumo_maio',
      'consumo_junho',
      'consumo_julho',
      'consumo_agosto',
      'consumo_setembro',
      'consumo_outubro',
      'consumo_novembro',
      'consumo_dezembro'
    ];
    const mes_modificado = string_meses[indice];

    let nova_curva_consumo = estadoFormulario.curva_consumo;
    nova_curva_consumo[indice] = novo_consumo_mes;

    const novo_consumo_medio = Math.round((nova_curva_consumo.reduce((acc, cur) => acc + cur)) / 12);

    const novoValor = {
      ...estadoFormulario,
      ['consumo_medio']: novo_consumo_medio,
      ['curva_consumo']: nova_curva_consumo,
      [mes_modificado]: novo_consumo_mes
    }

    setEstadoFormulario(novoValor);
  }

  function setValueObject(object) {
    const novoValor = {
      ...estadoFormulario,
      ...object
    }

    setEstadoFormulario(novoValor);
  }

  function handleChange(e) {
    restringirBotoes();
    
    const { name, value } = e.target;
    const novoValor = {
      ...estadoFormulario,
      [name]: parseFloat(value)
    }

    setEstadoFormulario(novoValor);
  };

  const handleSubmit = e => {
    e.preventDefault();

    ajustarNovaLista();

    const resultadoValidacao = validar(estadoFormulario);
    setErros(resultadoValidacao);

    Object.keys(resultadoValidacao).length > 0 && notificarErroPreenchimentoFormulario();
    
    setIsSubmitting(true);
  };

  useEffect(
    () => {
      if (Object.keys(erros).length === 0 && isSubmitting) {
        callback();
      };
    },
    [erros]
  );

  return {
    erros,
    alteracao_desconto_WEG,
    alteracao_concessionaria,
    alteracao_responsavel,
    alteracao_estrutura,
    alteracao_modulo,
    alteracao_media,
    alteracao_mes_consumo,
    setValueObject,
    handleChange,
    handleSubmit
  };
};






export default useForm;