import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BarraNavegacao, Configuracoes, MultiLevelDropdown, MultiLevelDropdownSair} from '..';

import './styles.css';

import logo from '../../assets/logo1.png';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

export default function Header () {
    const history = useHistory();

    const [tokenJSON, setTokenJSON] = useState({});

    useEffect(() => {
        setTokenJSON(JSON.parse(localStorage.getItem('sgc_br')));
    }, [])

    return (
        <header>
            <div className="header-top">
                <div className="header-logo">
                    {/* <h1 style={{color:'green'}}>LOGO</h1> */}
                    <img src={logo} alt="BRAZIL SOLUTION"/>
                </div>
                {/* <div className="header-search">
                    <FiSearch size={24} color="#000000" />
                </div> */}
                    
                    {/* <button onClick={() => history.push('/inicio')} className="header-icon">
                        <FiSettings size={18} color="#000000" />
                    </button> */}

                    {permissaoUsuario.verificar(47, tokenJSON.tipo_usuario) ? (
                        <div className="header-right-div">
                            <Configuracoes />
                            <MultiLevelDropdown />
                        </div>
                    ) : permissaoUsuario.verificar(10, tokenJSON.tipo_usuario) ? (
                        <div className="header-right-div-single">
                            <MultiLevelDropdown />
                        </div>
                    ) : (
                        <div className="header-right-div-single">
                            <MultiLevelDropdownSair />
                        </div>
                    )}

            </div>
            <div className="header-navbar">
                <BarraNavegacao />
            </div>
        </header>
    );
}