import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

export default function ModalPadrao2(props) {
    return (
        <div className="modal-fundo">
            <div className="modal2">
                <div className="modal-titulo">
                    {/* <FiAlertCircle size={30} /> */}
                    <p> {props.titulo} </p>
                </div>
                <div className="modal-conteudo">
                    <p> {props.mensagem1} </p>
                    <br/>
                    <br/>
                    {props.parametro1 &&  <> <p> <strong> {props.parametro1.nome} </strong> {props.parametro1.valor}</p> </> }
                    {props.parametro2 &&  <> <p> <strong> {props.parametro2.nome} </strong>  {props.parametro2.valor}</p> </> }
                    {props.parametro3 &&  <> <p> <strong> {props.parametro3.nome} </strong>  {props.parametro3.valor}</p> </> }
                    {props.parametro4 &&  <> <p> <strong> {props.parametro4.nome} </strong>  {props.parametro4.valor}</p> </> }
                    {props.parametro5 &&  <> <p> <strong> {props.parametro5.nome} </strong>  {props.parametro5.valor}</p> </> }
                    {props.parametro6 &&  <> <p> <strong> {props.parametro6.nome} </strong>  {props.parametro6.valor}</p> </> }
                    {props.parametro7 &&  <> <p> <strong> {props.parametro7.nome} </strong>  {props.parametro7.valor}</p> </> }
                    <br/>
                    <br/>
                    <p> {props.mensagemConfirmar} </p>
                </div>
                <div className="modal-acao">
                    <button className="modal-botao" onClick={(e) => props.fecharModal()}>
                        Cancelar
                    </button>
                    <button className="modal-botao" onClick={(e) => props.acaoModal()}>
                        {props.confirmar}
                    </button>
                </div>
            </div>
        </div>
    );
}