// Referências externas
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import { useCookies } from "react-cookie";
import { FiUser, FiLogOut } from 'react-icons/fi'

// Referências internas
import api from '../../services/api';
import generic_user from '../../assets/users/generic_user.png';
import andre from '../../assets/users/andre.jpg';
import helton from '../../assets/users/helton.jpg';
import goncalves from '../../assets/users/goncalves.png';
import ana_sabino from '../../assets/users/ana_sabino.png';
import paulina from '../../assets/users/paulina.png';
import patricia from '../../assets/users/patricia.jpg';
import marcelo from '../../assets/users/marcelo.png';
import flavia from '../../assets/users/flavia.jpg';
import guilherme from '../../assets/users/guilherme.jpg';
import lessa from '../../assets/users/lessa.jpg';
import marcos from '../../assets/users/marcos.jpg';
import joao from '../../assets/users/joao.jpg';
import pedro from '../../assets/users/pedro.jpg';
import valmir from '../../assets/users/valmir.png';
import pedroh from '../../assets/users/pedroh.png';
import leonardo from '../../assets/users/leonardo.png';
import george from '../../assets/users/george.jpg';
import iago from '../../assets/users/iago.png';
import vanderlei from '../../assets/users/vanderlei.jpg';
import suy from '../../assets/users/suy.jpg';
import alex_vieira from '../../assets/users/alex_vieira.jpg';
import beatriz from '../../assets/users/beatriz.jpg';

// Estilo
import './styles.css';

function NavItem(props) {
    const [open, setOpen] = useState(false);
    const [profilePicture, setProfilePicture] = useState(generic_user);

    const imagem_usuarios = {
        andre: andre,
        helton: helton,
        goncalves: goncalves,
        ana_sabino: ana_sabino,
        paulina: paulina,
        patricia: patricia,
        marcelo: marcelo,
        flavia: flavia,
        guilherme: guilherme,
        lessa: lessa,
        marcos: marcos,
        joao: joao,
        pedro: pedro,
        valmir: valmir,
        pedroh: pedroh,
        leonardo: leonardo,
        george: george,
        iago: iago,
        vanderlei: vanderlei,
        alex_vieira: alex_vieira,
        beatriz: beatriz,
        suy: suy,
        generic_user: generic_user
    }

    useEffect(() => {
        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));

        if (tokenJSON.imagem_usuario.includes('https')) {
            setProfilePicture(tokenJSON.imagem_usuario);
        } else {
            setProfilePicture(imagem_usuarios[tokenJSON.imagem_usuario]);
        }
    }, [])

    return (    
        <li className="nav-item">
            <a
                href="#"
                className="icon-button"
                onClick={() => setOpen(!open)}
                style={{
                    backgroundImage: 'url(' + profilePicture + ')',
                    backgroundPosition: 'center',
                    backgroundSize: '40px 40px',
                    backgroundRepeat: 'no-repeat',
                    boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.17)'
                }}>
            </a>
            
            {open && <a onClick={() => setOpen(!open)} className="background-click"></a> }

            {open && props.children}
        </li>
    );
}

function DropdownMenu() {
    const [removeCookie] = useCookies(["sessionId"]);

    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
    }, [])

    const history = useHistory();

    async function handleLogout() {
        try {
            localStorage.removeItem('sgc_br');
            await api.delete('logout');
            removeCookie("sessionId");

        } catch (error) { }
        history.push('/');
    }

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    function DropdownItem(props) {

        if (props.logout) {
            return (
                <a
                    href=""
                    className="menu-item"
                    onClick={() => {handleLogout()}}
                >
                    <span className="icon-button">{props.leftIcon}</span>
                    {props.children}
                    <span className="icon-right">{props.rightIcon}</span>
                </a>
            );
        } else {
            return (
                <Link className="menu-item" to={props.destino} >
                    <span className="icon-button">{props.leftIcon}</span>
                    {props.children}
                    <span className="icon-right">{props.rightIcon}</span>
                </Link>
            );
        }
    }

    return (
        <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>

            <CSSTransition
                in={activeMenu === 'main'}
                timeout={500}
                classNames="menu-primary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    <DropdownItem
                        leftIcon={<FiLogOut size={18} />}
                        logout={true}>
                        Sair
                    </DropdownItem>
                </div>
            </CSSTransition>
        </div>
    );
}

export default function MultiLevelDropdownSair() {
    return (
        <NavItem>
            <DropdownMenu></DropdownMenu>
        </NavItem>
    );
}